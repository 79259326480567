import { useEffect } from 'react';

// Components
import PageTitle from '../components/PageTitle';
import SectionHeader from '../components/global/SectionHeader';


export default function Blog() {

    useEffect(() => {
        let blogScript = document.createElement("script");
        blogScript.setAttribute('src', 'https://io.dropinblog.com/embedjs/3c5ec6d9-85bf-4771-b7cd-332e5fbc28c3.js');
        document.body.appendChild(blogScript);
    }, []);

  	return (

		<div
            className='fm-page'
            data-page='blog'
            // The selector (class or id) of the text element relative to which the header background will fill in
            data-header-fill='#dib-posts'
        >

            <PageTitle
                title='Blog'
                canonical='blog'
                desc='Flux Marine Blog Page'
            />

            <section>
                <div id="dib-posts"></div>
            </section>

        </div>
  	)

}