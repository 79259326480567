import {useEffect, useRef, useState} from 'react';

const useOnScreen = (options, once = false) => {

    const elementRef = useRef(null);
    const [onScreen, setOnScreen] = useState(false);

    const callbackFunction = (entries, observer) => {
        const [ entry ] = entries;
        setOnScreen(entry.isIntersecting);
        if (once && entry.isIntersecting) {
            observer.unobserve(elementRef.current);
        }
        
    };

    useEffect(() => {
        const observer = new IntersectionObserver(callbackFunction, options);

        if (elementRef.current) {
            observer.observe(elementRef.current);
        }

        return () => {
            if (elementRef.current) {
                observer.unobserve(elementRef.current);
            }
        }
    }, []);
  
    return [elementRef, onScreen];
};

export default useOnScreen;