const hullColorConfig = {

    choices: [
        {
            dependentIds: [1,3],
            id: 1,
            name: 'Shark Gray',
            hexVal: '#c8cbcc'
        },
        {
            dependentIds: [1,3],
            id: 2,
            name: 'Midnight Blue',
            hexVal: '#0C324A'
        },
        {
            dependentIds: [3],
            id: 3,
            name: 'Crystal Blue',
            hexVal: '#7a97ab'
        },
        {
            dependentIds: [1],
            id: 4,
            name: 'Black',
            hexVal: '#000000'
        },
        {
            dependentIds: [2],
            id: 5,
            name: 'Light Gray',
            hexVal: '#F4F4F4'
        }
    ],

    choiceDependency: 'model',

    choiceTemplate: (obj, clickHandler, currSelection) => (
        <button
            className={`fm-studio__btn fm-studio__btn--color ${currSelection === obj.id ? 'fm-studio__btn--selected' : ''}`}
            key={obj.hexVal}
            onClick={clickHandler}
            style={{background: obj.hexVal}}
        >
            <span className='visually-hidden'>{obj.name}</span>   
        </button>
    ),

    fieldName: 'hullColor',

    name: 'Hull Color',

    // clickHandler={handleOutboardColorClick}
};



export default hullColorConfig;