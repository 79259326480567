import React, { createContext, useState } from 'react';

export const GlobalContext = createContext();

const GlobalContextProvider = (props) => {

    const cdnUrl = 'https://d25bwcki5hef1e.cloudfront.net';

    /* const [variableOne, setVariableOne] = useState(`somethingRandom`); */

    return (
         <GlobalContext.Provider 
            value={{
                cdnUrl/* ,
                variableOne */
             }}>
               {props.children}
         </GlobalContext.Provider>
    )
}
export default GlobalContextProvider;