import React from 'react';
import Header from '../components/global/Header';
import Footer from '../components/global/Footer';

import { Outlet } from 'react-router-dom';

export default function MainLayout() {


  	return (
		<>
			<Header />
	
            <main>

                <Outlet />

            </main>

            <Footer />
		
		</>
  	)
}
