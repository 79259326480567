import { useContext, useRef, useEffect, useState } from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from './Button';

import { GlobalContext } from '../../contexts/GlobalContext';

const ImgTile = (props) => {

    const { cdnUrl } = useContext(GlobalContext);

    return (
        <a
            className={`fm-mosaic-tile${props.large ? ' fm-mosaic-tile--lg ' : ''}${props.wide ? ' fm-mosaic-tile--wide' : ''}`}
            data-pos={props.tilePos}
            href={props.src}
            onClick={props.clickHandler}
        >

            <img
                alt={props.altText}
                className='fm-mosaic-tile__img img-fluid'
                data-source={`${cdnUrl}${props.src}`}
                // Possibly empty this src attribute on load in favor of lazy loading via dataSource
                src={`${cdnUrl}${props.src}`}
                /* width={}
                height={} */
            />

        </a>
    );
};


const Viewer = (props) => {

    const { cdnUrl } = useContext(GlobalContext);

    const [currIndex, setCurrIndex] = useState(props.imgIndex);

    const imgRef = useRef();
    const controlsRef = useRef();

    const handleNavClick = (dir) => {
        setCurrIndex(prevState => dir === 'prev' ? prevState - 1 : prevState + 1)
    };

    const currImg = props.images[currIndex];

    useEffect(() => {

        setTimeout(() => {
            const checkClickLocation = (e) => {
                if (
                    imgRef.current 
                    && !imgRef.current.contains(e.target)
                    && controlsRef.current
                    && !controlsRef.current.contains(e.target)
                ) {
                    props.close();
                }
            }

            document.addEventListener('click', checkClickLocation);
            return () => {
                document.removeEventListener('click', checkClickLocation);
            };
        }, 300);

    }, []);
    
    return (
        <div className='fm-mosaic-viewer'>

            <div className='fm-mosaic-viewer__inner d-flex align-items-center justify-content-center'>
                
                <img
                    alt={currImg.altText}
                    className='img-fluid'
                    ref={imgRef}
                    src={`${cdnUrl}${currImg.src}`}
                    /* width={}
                    height={} */
                />

            </div>

            <div className='fm-mosaic-viewer__controls' ref={controlsRef}>

                <div className='fm-mosaic-viewer__controls-inner h-100 d-flex justify-content-between align-items-center'>

                    <button
                        className={`fm-mosaic-viewer__btn fm-mosaic-viewer__btn--prev ${currIndex === 0 ? 'disabled' : ''}`}
                        onClick={() => handleNavClick('prev')}
                        tabIndex={currIndex === 0 ? -1 : ''}
                    >
                        <FontAwesomeIcon icon='fa-solid fa-chevron-left' />
                    </button>

                    <button
                        className={`fm-mosaic-viewer__btn fm-mosaic-viewer__btn--close fm-fw-medium`}
                        onClick={props.close}
                    >
                        Close
                    </button>

                    <button
                        className={`fm-mosaic-viewer__btn fm-mosaic-viewer__btn--next ${currIndex === props.images.length - 1 ? 'disabled' : ''}`}
                        onClick={() => handleNavClick('next')}
                        tabIndex={currIndex === props.images.length - 1 ? -1 : ''}
                    >
                        <FontAwesomeIcon icon='fa-solid fa-chevron-right' />
                    </button>

                </div>

            </div>

        </div>
    );

}

/*
    Moasic Component Props:

        buttonLabel (string): Text label for the button that renders additional images on click
        customClass (string): Additional class to add to the button
        images (array): List of images with which to populate the component
        interval (integer): Number images to show on load and render on each click of the 'Show More' button
*/

export default function Mosaic({ buttonLabel = 'Show More', customClass, images, interval = 7}) {

    // Portion of images list to render (Initially the first 7 images)
    const [gridContents, setGridContents] = useState(images.slice(0,interval));

    // Store enlarged image data object in state
    const [enlargedImg, setEnlargedImg] = useState(null);

    const buildClassString = () => {
        let classString = 'fm-mosaic';

        if (customClass) {
            classString += ` ${customClass}`;
        }

        return classString;
    };

    const handleImgClick = (e, imgIndex) => {
        e.preventDefault();
        setEnlargedImg(imgIndex);
    }

    const handleMoreClick = () => {
        setGridContents(prevState => {

            const prevCount = prevState.length;
            const newCount = prevCount + interval;

            return prevState.concat(
                images.slice(prevCount, newCount > images.length ? images.length : newCount)
            );
        });
    }

    /* useEffect(() => {
        
        // Connect to HubSpot Files API and get images from a particular folder to drive an instance of this component.
        // Use two state fields, one for the full images list and one for the visible images list.  Currently, we're only using 1

    }, []); */

	return (

        <div
            className={buildClassString()}
        >

            <div className='fm-mosaic__grid fm-max-out'>
                
                { gridContents.map((img, i) =>
                
                    <ImgTile
                        {...img}
                        clickHandler={(e) => handleImgClick(e, i)}
                        key={`${img.src}-${i}`}
                        tilePos={i + 1 <= 11 ? i + 1 : i + 1 - 11}
                    />

                )}

            </div>


            { gridContents.length < images.length && (
                <Button
                    customClass='fm-mosaic__more-btn'
                    label={buttonLabel}
                    onClick={handleMoreClick}
                    theme='secondary'
                />
            )}

            { enlargedImg !== null && (
                <Viewer
                    close={() => setEnlargedImg(null)}
                    images={images}
                    imgIndex={enlargedImg}
                />
            )}

        </div>

	)

}
