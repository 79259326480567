import Button from "../global/Button";

export default function FormFeedback({ ctaClickHandler, ctaLabel, headline = 'Thank You!', homepageBtn = true, message }) {

	return (
        <div className='fm-form-feedback'>

            <p className='fm-form-feedback__headline fm-section-header__title'>{headline}</p>

            {message && (
                <p className='fm-form-feedback__msg'>{message}</p>
            )}

            <div className='fm-form-feedback__button-row d-flex flex-column flex-md-row align-items-center justify-content-md-center'>

                { ctaClickHandler && ctaLabel && (
                    <Button
                        onClick={ctaClickHandler}
                        label={ctaLabel}
                        theme='secondary'
                    />
                )}

                {homepageBtn && (

                    <Button
                        label={'Return to Homepage'}
                        url={'/'}
                        theme='secondary'
                    />

                )}

            </div>
    
        </div>
	);

}