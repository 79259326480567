const specsConfig = [

    // FM15
    /* {
        name: 'FM15',

        headline: 'Repower With Our 15HP Outboard',

        hullType: 'Highfield CL340',

        // MSRP in $
        msrp: {
            standard: '5,400',
            extended: '6,700',
        },

        // Power Specs in HP units
        powerSpecs: {
            rating: 15,
            acceleration: 25
        },

        activeRange: {
            standard: 20
        },

        
        // Cruising/Top Speeds in mph
        speeds: {
            cruising: 16,
            top: 22
        },

        included: [
            'Remote Throttle w/ Display',
            '3.3kW Charger',
            'Electric Tilt',
            'Portable Charging Cable'
        ],

        available: '2025'
    }, */

    // FM40
    {

        name: 'FM40',

        headline: 'Repower With Our 40HP Outboard',

        hullType: 'Highfield SP390',

        // MSRP in $
        msrp: {
            standard: '18,700',
            extended: '26,600',
        },

        // Power Specs in HP units
        powerSpecs: {
            rating: 40,
            acceleration: 70
        },

        activeRange: {
            standard: 26
        },

        // Cruising/Top Speeds in mph
        speeds: {
            cruising: 24,
            top: 32
        },

        included: [
            'Remote Throttle w/ Display',
            '6.6kW Charger',
            'Electric Tilt',
            'Portable Charging Cable'
        ],

        available: '2025'
    },

    // FM70
    {
        name: 'FM70',
    
        headline: 'Repower With Our 70HP Outboard',

        hullType: 'Highfield SP460',

        // MSRP in $
        msrp: {
            standard: '26,700',
            extended: '34,600',
        },
    
        // Power Specs in HP units
        powerSpecs: {
            rating: 70,
            acceleration: 100
        },

        activeRange: {
            standard: 30
        },

        // Cruising/Top Speeds in mph
        speeds: {
            cruising: 25,
            top: 35
        },
    
        // Integrated Battery Management System details
        included: [
            'Remote Throttle w/ Display',
            '6.6kW Charger',
            'Electric Tilt',
            'Portable Charging Cable'
        ],

        available: 'Available'
    },

    // FM100
    {
        name: 'FM100',
    
        headline: 'Repower With Our 70HP Outboard',

        hullType: 'Scout Dorado 215',

        // MSRP in $
        msrp: {
            standard: '50,600',
            extended: '58,500',
        },
    
        // Power Specs in HP units
        powerSpecs: {
            rating: 100,
            acceleration: 150
        },

        activeRange: {
            standard: 25
        },

        // Cruising/Top Speeds in mph
        speeds: {
            cruising: 24,
            top: 30
        },
    
        // Integrated Battery Management System details
        included: [
            'Remote Throttle w/ Display',
            '6.6kW Charger',
            'Electric Tilt',
            'Portable Charging Cable'
        ],

        available: 'Available'
    }
];

export default specsConfig;