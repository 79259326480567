import { cloneElement, createRef, useEffect, useRef, useState } from 'react';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import PageTitle from '../components/PageTitle';

import { metaDesc, questions } from '../config/faqs/questionConfig';

// Assets

const Question = ({ content, index, section }) => {

    const [active, setActive] = useState(false);

    const toggleAccordion = () => {
        setActive(!active);
    };

    return (
        <div className='fm-faq__block'>

            <button
                aria-controls={`${section}${index+1}-collapse`}
                aria-expanded={'false'}
                className='fm-faq__question-btn d-flex flex-row justify-content-between align-items-center w-100 text-start'
                data-bs-target={`#${section}${index+1}-collapse`}
                data-bs-toggle='collapse'
                onClick={toggleAccordion}
            >

                <h3 className='fm-faq__question-text'>
                    {content.question}
                </h3>

                <div>
                    <FontAwesomeIcon className='fm-faq__icon' icon={`fa-solid ${active ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
                    <span className='visually-hidden'>{active ? 'Collapse question' : 'Expand to view answer'}</span>
                </div>

            </button>
                    
            <div className='collapse' id={`${section}${index+1}-collapse`}>
                <div className='fm-faq__answer'>
                    <p>{content.answer}</p>
                </div>
            </div>

        </div>
    );
};


export default function FrequentlyAskedQuestions() {

    const [activeCategory, setActiveCategory] = useState(Object.keys(questions)[0]);

    const questionLabels = Object.keys(questions);

  	return (
        <>

            <PageTitle
                title='FAQs'
                desc={metaDesc}
            />

            <div className='fm-page' data-page='faq'>

                <section className='fm-faq'>

                    <div className='fm-max-out'>

                        <h1 className='text-center'>Frequently Asked Questions</h1>

                        <div className='fm-faq__sections'>

                            <ul className='fm-faq__tablist d-flex flex-row flex-wrap justify-content-center' role='tablist'>

                                {questionLabels.map((section, i) =>
                                    <li key={section}>
                                        <button className={`fm-btn fm-faq__btn ${section === activeCategory ? 'fm-faq__btn--active' : 'fm-btn--secondary'}`} onClick={() => setActiveCategory(section)}>
                                            <span>{section}</span>
                                        </button>
                                    </li>
                                )}

                            </ul>

                        </div>

                        <div className='fm-faq__content-wrap tab-content'>

                            {questionLabels.map((section, i) => {

                                const questionsArr = questions[section];
                                const questionCount = questionsArr.length;
                                const halfway = Math.ceil(questionCount / 2);
                                const firstCol = questionsArr.slice(0, halfway);
                                const secondCol = questionsArr.slice(halfway);


                                return (
                                    <div
                                        aria-labelledby='category-tab'
                                        className={`fm-faq__cat flex-column flex-lg-row tab-pane fade ${activeCategory === section ? 'show active d-flex' : ''}`}
                                        key={section}
                                        role='tabpanel'
                                    >

                                        <div className='fm-faq__col'>
                                            {firstCol.map((questionObj, j) => 

                                                <Question
                                                    content={questionObj}
                                                    index={j}
                                                    section={section}
                                                    key={`${questionObj.question}-${j}`}
                                                />

                                            )}
                                        </div>

                                        <div className='fm-faq__col'>
                                            {secondCol.map((questionObj, k) => 

                                                <Question
                                                    content={questionObj}
                                                    index={k+firstCol.length}
                                                    section={section}
                                                    key={`${questionObj.answer}-${k}`}
                                                />

                                            )}
                                        </div>

                                    </div>
                                )
                            })}

                        </div>

                    </div>

                </section>

            </div>

        </>
  	)

}
