// Configuration Array
const heroCarouselConfig = [

    // First Slide
    {
        altText: 'Close up of a wake behind an electric flux marine motor from the side.',
        image: '/homepage/hero-carousel/slide_one_img_v4.jpg',
        text: '100% Electric Outboards',
        textPositionX: 'right',
        textPositionY: 'top',
        ctaText: 'Configure',
        ctaUrl: '/configure',
        stateObj: {modelId: 4}
    },

    // Second Slide
    {
        altText: 'An electric dual console boat moves away at speed.',
        image: '/homepage/hero-carousel/slide_two_06_2024.jpg',
        text: 'Curated Electric Boat Packages',
        textPositionX: 'left',
        textPositionY: 'top',
        ctaText: 'Configure',
        ctaUrl: '/configure'
    },
    

    // Third Slide
    {
        altText: 'An electric highfield inflatable boat glides above the water.',
        image: '/homepage/hero-carousel/slide_three_06_2024.jpg',
        text: 'Experience Electric Boating',
        textPositionX: 'right',
        textPositionY: 'top',
        theme: 'light',
        ctaText: 'Book A Sea Trial',
        /* ctaUrl: '/sea-trial' */
        ctaUrl: '/about',
        stateObj: {
            scrollTo: 1,
            inquiry: 'Request a Sea Trial'
        }
    }

];
  
export default heroCarouselConfig;