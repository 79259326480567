import { useContext } from 'react';

import { GlobalContext } from '../../contexts/GlobalContext';

// Flux Marine Components
import SectionHeader from './SectionHeader';
import Button from './Button';

/*
    Content Panel Component Props:

        customClass (string): additional class to add to the button (optional)

        altText (string): Alternate text for screen readers to describe image
        image (string): Source path of image
        title (string): Title text content
        subhead (string): Sub-heading text content (optional)
        body (string): Body paragraph text content
        ctaText (string): Label for optional button  (optional)
        ctaUrl (string): URL to which the button will direct on click (optional)
        ctaTarget (string): Link target for opening in a new tab (optional)

*/

const ImageContent = ({altText, src}) => {
    
    const { cdnUrl } = useContext(GlobalContext);

    return (
        <div className='fm-content-panel__img-wrap'>
            <div
                className={`fm-content-panel__img h-100`}
                style={{backgroundImage: `url(${cdnUrl}${src})`}}
            >
                {/* {image && (
                    <img className='fm-content-panel__img img-fluid' src={image} alt={altText} />
                )} */}
                <span className='visually-hidden'>
                    {altText}
                </span>
            </div>
        </div>
    );
};

const TextContent = ({ body, buttonConfig, disclaimer, subhead, title }) => {
    return (
        <div className='fm-content-panel__txt-wrap align-self-lg-center'>

            <div className='fm-content-panel__txt-inner text-center text-lg-start h-100 d-flex flex-column justify-content-between'>

                <div>

                    <SectionHeader
                        title={title}
                        subtitle={subhead}
                    />

                    <p className='fm-content-panel__txt-body'>
                        {body}
                    </p>

                    {disclaimer && (
                        <p className='fm-content-panel__disclaimer'>
                            &#42;{disclaimer}
                        </p>
                    )}

                </div>

                {buttonConfig.url && (
                    <Button
                        label={buttonConfig.label}
                        target={buttonConfig.target}
                        url={buttonConfig.url}
                        stateObj={buttonConfig.stateObj}
                    />
                )}

            </div>

        </div>
    );
};


export default function ContentPanel({ altText = 'Large Flux Marine image', ctaTarget, ctaText, ctaUrl, body, disclaimer, image, subhead, textPosition = 'right', stateObj, title }) {

    const imageTile = (
        <ImageContent
            altText={altText}
            src={image}
        />
    );

    const textTile = (
        <TextContent
            body={body}
            buttonConfig={{
                label: ctaText,
                target: ctaTarget,
                url: ctaUrl,
                stateObj: stateObj
            }}
            disclaimer={disclaimer}
            subhead={subhead}
            title={title}
        />
    )

	return (

		<div className='fm-content-panel d-flex flex-column flex-lg-row'>

            {/* Left Side Content */}
            {textPosition === 'left' ? textTile : imageTile}
            {/* END Left Side Content */}

            {/* Right Side Content */}
            {textPosition === 'left' ? imageTile : textTile}
            {/* END Right Side Content */}

        </div>

	)
}
