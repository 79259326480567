// Configuration Object
const socialLinks = [
    /* {
        name: 'YouTube',
        url: 'https://www.youtube.com/channel/UCpkOnzw0IWtcwnifEBDkK3Q?app=desktop',
        icon: ''
    }, */
    {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/company/flux-marine-ltd/',
        icon: ''
    },
    {
        name: 'Facebook',
        url: 'https://www.facebook.com/Flux-Marine-407621316380307',
        icon: ''
    },
    {
        name: 'Instagram',
        url: 'https://www.instagram.com/fluxmarine/',
        icon: ''
    },
];

export default socialLinks;