const technologyConfig = {

    availableToday: [
        
        {
            label: {
                img: '/outboards/technology/FM100-Text.png',
                text: 'FM100'
            },

            contPower: 100,

            accPower: 150,

            colors: [
                {
                    color: 'black',
                    default: true,
                    img: '/outboards/technology/FM70_100_Graphite_07_24.png'
                },
                {
                    color: 'titanium',
                    default: false,
                    img: '/outboards/technology/FM70_100_Titanium_07_24.png'
                },
                {
                    color: 'silver',
                    default: false,
                    img: '/outboards/technology/FM70_100_Silver_07_24.png'
                }
            ],

            cta: {
                label: 'Configure',
                stateObj: {
                    modelId: 4
                }
            }

        },

        {
            label: {
                img: '/outboards/technology/FM70-Text.png',
                text: 'FM70'
            },

            contPower: 70,
            
            accPower: 100,

            colors: [
                {
                    color: 'black',
                    default: false,
                    img: '/outboards/technology/FM70_100_Graphite_07_24.png'
                },
                {
                    color: 'titanium',
                    default: false,
                    img: '/outboards/technology/FM70_100_Titanium_07_24.png'
                },
                {
                    color: 'silver',
                    default: true,
                    img: '/outboards/technology/FM70_100_Silver_07_24.png'
                }
            ],

            cta: {
                label: 'Configure',
                stateObj: {
                    modelId: 4
                }
            }

        }

    ],

    betterBoating: {

        title: 'A Better Boating Experience',

        body: 'Flux Marine Outboards are built from the ground up to redefine the standard of marine propulsion. Command attention without the hassle of fuel docks, oil changes, or winterization. Enjoy a full day of boating on a single overnight charge.'
    
    },

    scrollPanel: {

        title: 'The Advantage of Electric Power',

        altText: 'Alt Text',

        image: '/outboards/technology/outboard-scroller-img.jpg',

        body: <>
            <p>
                Flux's electric propulsion delivers continuous prop-power of 40, 70, or 100HP with instant torque that will push you back in your seat. Effortlessly jump on plane fast and embark on your next boating adventure.
            </p>
        </>

    },

    contentPanel: {

        altText: 'Rendering of the upper portion of the 100% Electric Flux Marine outboard with the rear indicator light',

        image: '/outboards/technology/outboard_unit_upper.png',

        title: 'Enjoy the Sounds of the Water',

        body: (<>
            Electric motors operate quietly with no idling or rumbling sounds. Take in the tranquility of the wind, water, and conversation during your boat ride.
            <br/>
            <br/>
            The rear indicator light is a built-in safety feature. The motor emits no exhaust or fumes and makes no noise when turned on, so the light cautions other boaters and those in the water.
        </>)

    },

    design: {

        img: '/outboards/technology/outboard_lower_unit.png',

        contentBlocks: [

            (<>
                <h2>Simplified Design</h2>
                <h3>No Exhaust</h3>
                <h3>No Water Intake</h3>
                <h3>No Oil Changes</h3>
            </>),

            (<>
                <h3>No Exhaust Fumes</h3>
                <p>Owning an electric motor means no more burning gasoline while cruising or idling on the water. With no exhaust, there's also no need for an exhaust pipe, resulting in a more efficiently designed lower unit, and the elimination of any potential leakage of unburned gasoline.</p>
                <p>This not only ensures a more pleasurable ride on the water but also contributes to a healthier environment.</p>
            </>),

            (<>
                <h3>No Water Intake</h3>
                <p>Our closed-loop internal cooling system maintains an efficient operating temperature while making seawater intake obsolete. You won't have to worry about internal corrosion or flushing your system between bodies of water.</p>
            </>),

            (<>
                <h3>No Oil Changes</h3>
                <p>Our simplified and  enhanced drivetrain uses no oil. Reduce the time and money you spend on maintenance.</p>
            </>)

        ]
    }

};

export default technologyConfig;