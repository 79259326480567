const outboardColorConfig = {

    choices: [
        {
            dependentIds: [1,2,3,4],
            id: 1,
            name: 'Black',
            hexVal: '#000000'
        },
        {
            dependentIds: [1,2,3,4],
            id: 2,
            name: 'Titanium',
            hexVal: '#aa9f8e'
        },
        {
            dependentIds: [2,3,4],
            id: 3,
            name: 'Silver',
            hexVal: '#C8CBCC'
        }
    ],

    choiceDependency: 'power',

    choiceTemplate: (obj, clickHandler, currSelection) => (
        <button
            className={`fm-studio__btn fm-studio__btn--color ${currSelection === obj.id ? 'fm-studio__btn--selected' : ''}`}
            key={obj.hexVal}
            onClick={clickHandler}
            style={{background: obj.hexVal}}
        >
            <span className='visually-hidden'>{obj.name}</span>   
        </button>
    ),

    fieldName: 'outboardColor',

    name: 'Outboard Color',

    // clickHandler={handleOutboardColorClick}
};



export default outboardColorConfig;


/* choiceTemplate={(obj) => (
    <button
        className={`fm-studio__btn fm-studio__btn--color ${selections.outboardColor === obj.id ? 'fm-studio__btn--selected' : ''}`}
        key={obj.hexVal}
        onClick={() => handleOutboardColorClick(obj)}
        style={{background: obj.hexVal}}
    >
        <span className='visually-hidden'>{obj.name}</span>   
    </button>
)} */