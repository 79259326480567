import { Link, useLocation } from 'react-router-dom';

import { useContext, useRef, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { GlobalContext } from '../../contexts/GlobalContext';

import navMenuConfig from '../../config/navigation-menu-config';

export default function Header() {

    const { cdnUrl } = useContext(GlobalContext);

    const { pathname } = useLocation();

    const [fill, setFill] = useState(false);

	// Header Reference
    const headerRef = useRef(null);

    const [navState, setNavState] = useState([...navMenuConfig]);

    const [hideNavItems, setHideNavItems] = useState(false);

    // Reference to first <section> element in main area of the page
    // let firstSection;
    // let targetSection;
    let targetElement;

    const toggleLinks = (index) => {
        setNavState(prevState => {
            let updated = [...prevState];
            updated[index].expanded = !prevState[index].expanded;
            return updated;
        });
    };

    const handleOffcanvasClose = () => {
        setTimeout(() => {
            setNavState([...navMenuConfig]);
        }, 1000);
    };

    /*
        On scroll:
            - Check the position of the bottom of the first <section> element relative to a portion of the height of the window
            - If it's bottom position is less than 20% of the height of the window, update state to trigger hidden class on navbar items
            - Otherwise, update state to trigger removal of hidden class on navbar items
    */
    /* const handleScroll = () => {
        const windowHeight = window.innerHeight;

        if (firstSection.getBoundingClientRect().bottom <= (windowHeight * 0.2) ) {
            if (!hideNavItems) {
                setHideNavItems(true);
            }
        } else {
            if (hideNavItems) {
                setHideNavItems(false);
            }
        }
    };

    useEffect(() => {
        // Assign firstSection reference to first section on page
        firstSection = document.querySelector('.fm-page > section:first-child');

        // Add scroll listener & handler
        document.addEventListener('scroll', handleScroll);
    }); */



    /*
        On scroll:
            - If the top of the first block of text content (somewhat a sitewide standard) or a custom targeted element has been reached, update state to trigger filled class on header
            - Otherwise, update state to trigger removal of the filled class on header
    */
    const handleScroll = () => {
        const windowHeight = window.innerHeight;
        const headerHeight = headerRef.current.clientHeight;

        // if (!fill && targetSection.getBoundingClientRect().bottom <= headerRef.current.clientHeight ) {
        if (!fill && targetElement && window.scrollY >= (targetElement.getBoundingClientRect().top - 55)) {
            setFill(true);
        } else {
            setFill(false);
        }
    };

    useEffect(() => {

        // Retrieve the page type from data attribute
        const page = document.querySelector('.fm-page'),
            pageType = page.getAttribute('data-page');

        // If the page is basic and doesn't need the scroll, functionality, fill in the header
        if (['faq', 'form', 'privacy-policy', 'studio'].includes(pageType)) {
            setFill(true);
        }
        
        // Otherwise...
        else {

            // Retrieve optional data attribute containing ID of scroll element after which to fill in the header
            const dataScrollTarget = page.getAttribute('data-header-fill');
    
            // Assign firstSection reference to first section on page
            /* targetSection = dataScrollTarget ? 
                document.getElementById(dataScrollTarget)
                : document.querySelector('.fm-page > section:first-of-type'); */

            targetElement = document.querySelector(dataScrollTarget);

            // Add scroll listener & handler
            document.addEventListener('scroll', handleScroll);
        }

    }, []);


	return (

		<header className={`fm-header${pathname === '/outboards' ? ' fm-header--light' : ''}${fill ? ' fm-header--filled' : ''} sticky-top`} ref={headerRef}>

            <nav className='fm-header__inner fm-max-out d-flex justify-content-between align-items-center'>

                <div className='fm-header__brand'>
                    <a className='fm-header__brand-link' href='/'>
                        <img
                            alt='Flux Marine'
                            className='fm-header__brand-img img-fluid'
                            src={
                                pathname === '/outboards' && !fill ?
                                    `${cdnUrl}/brand/flux_logo_light_full.svg`
                                    : `${cdnUrl}/brand/flux_logo_dark_full.png`
                            }
                            width={240}
                            height={12}
                        />
                    </a>
                </div>

                <div className={`fm-header__nav d-none d-lg-flex justify-content-lg-between align-items-lg-center ${hideNavItems ? 'fm-header__nav--hidden' : ''}`} role='navigation'>

                    <a className='fm-header__nav-link' href='/outboards'>Outboards</a> {/* basic-hover */}

                    <div className='fm-header__nav-item dropdown'>
                        
                        <a className='fm-header__nav-link dropdown-toggle' /* href='/packages' */ href="#" data-bs-toggle='dropdown'>
                            Boat Packages
                        </a>
                        
                        <ul className='fm-header__dropdown-menu dropdown-menu'>

                            <li className='fm-header__dropdown-item'>
                                <a className='' href='/packages/rigid-inflatables'>Rigid Inflatables</a> {/* basic-hover */}
                            </li>

                            <li className='fm-header__dropdown-item'>
                                <a className='' href='/packages/dual-console'>Dual Console</a> {/* basic-hover */}
                            </li>

                            <li className='fm-header__dropdown-item'>
                                <a className='' href='/packages/center-console'>Center Console</a> {/* basic-hover */}
                            </li>

                        </ul>

                    </div>

                    <a className='fm-header__nav-link' href='/about'> {/* basic-hover */}
                        About Us
                    </a>

                    <a
                        className='fm-header__nav-link'
                        href='https://fluxmarine.teamtailor.com/'
                        target='_blank'
                    > {/* basic-hover */}
                        Careers
                    </a>

                </div>

                <div className='fm-header__menu-wrapper d-flex justify-content-end'>

                    <button aria-label='Toggle Offscreen Navigation Menu' className='fm-header__menu-btn navbar-toggler d-lg-none' type='button' data-bs-toggle='offcanvas' data-bs-target='#offcanvasNavbar' aria-controls="offcanvasNavbar">
                        {/* <span>Menu</span> */}
                        <FontAwesomeIcon icon='fa-solid fa-bars' size='lg' />
                    </button>

                </div>

                <div
                    className='fm-header-menu offcanvas offcanvas-end' 
                    tabIndex='-1'
                    id='offcanvasNavbar'
                    aria-labelledby='offcanvasNavbarLabel'
                >
                    
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Menu</h5>
                        <button type="button" className="btn-close" onClick={handleOffcanvasClose} data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>

                    <div className="offcanvas-body">

                        <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">

                           {/*  Do we need to handle logic for adding 'active' className to proper anchor?? */}
                           {/* Example: <a className="nav-link active" aria-current="page" href="#">Home</a> */}
                            {navState.map((parentNavItem, i) => 
                                
                                <li className='nav-item fm-header-menu__item fm-header-menu__item--outer' key={`${parentNavItem.label}-${i}`}>

                                    {parentNavItem.url && (
                                    
                                        /* <a className='fm-header-menu__link nav-link text-uppercase d-flex align-items-center' href={parentNavItem.url}>
                                            {parentNavItem.label}
                                        </a> */

                                        <a className='fm-header-menu__link nav-link' href={parentNavItem.url}>
                                            <span className='fm-header-menu__link-title'>{parentNavItem.label}</span>
                                            {/* <span className='fm-header-menu__link-mask'>
                                                <div className='fm-header-menu__link-container'>
                                                    <span className='fm-header-menu__link-title fm-header-menu__link-title--1'>{parentNavItem.label}</span>
                                                    <span className='fm-header-menu__link-title fm-header-menu__link-title--2'>{parentNavItem.label}</span>
                                                </div>
                                            </span> */}
                                            {/* <div className='fm-header-menu__link-icon'>
                                                <FontAwesomeIcon className='d-none' icon="fa-solid fa-arrow-right" />
                                                <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                                            </div> */}
                                        </a>
                                    )}

                                    {!parentNavItem.url && (
                                        <button className='w-100 fm-header-menu__link nav-link d-flex justify-content-between align-items-center' onClick={() => toggleLinks(i)} style={{border: 'none', background: 'none'}}>
                                            {parentNavItem.label}
                                            <FontAwesomeIcon icon={`fa-solid fa-chevron-${parentNavItem.expanded ? 'up' : 'down'}`} />
                                        </button>
                                    )}

                                    { parentNavItem.pageLinks && (

                                        <ul>

                                            {parentNavItem.expanded && parentNavItem.pageLinks.map((childNavItem, j) =>

                                                <li className='fm-header-menu__item fm-header-menu__item--inner' key={`${childNavItem.label}-${j}`}>

                                                    {/* <a
                                                        className='fm-header-menu__link nav-link'
                                                        href={childNavItem.url}
                                                        rel={childNavItem.target && 'noopener noreferrer'}
                                                        target={childNavItem.target && childNavItem.target}
                                                    >
                                                        <span className='fm-header-menu__link-title'>{childNavItem.label}</span>
                                                    </a> */}

                                                    <Link
                                                        className='fm-header-menu__link nav-link'
                                                        onClick={() =>{ document.querySelector('#offcanvasNavbar .btn-close').click()} }
                                                        to={childNavItem.url}
                                                        rel={childNavItem.target && 'noopener noreferrer'}
                                                        state={childNavItem.state}
                                                        target={childNavItem.target && childNavItem.target}
                                                    >
                                                        <span className='fm-header-menu__link-title'>{childNavItem.label}</span>
                                                    </Link>

                                                </li>

                                            )}

                                        </ul>

                                    )}

                                </li>

                            )}

                        </ul>
                    </div>
                </div>

            </nav>

		</header>

	)
}
