import { Link } from "react-router-dom";

const powerConfig = {

    additionalContent: (
        <p className='fm-studio__disclaimer text-center'>
            Looking for more than 100HP?
            &nbsp;
            <Link
                to='/about'
                state={{ scrollTo: 1 }}
            >
                Contact us directly
            </Link>
            &nbsp;to customize your order.
        </p>
    ),

    choices: [
        /* {
            appendToChoice: <span>(Available 2025)</span>,
            dependentIds: [4],
            id: 1,
            name: 15,
            hullModel: 'Highfield-SP390',
            outboardColorIds: [1,2],
            unit: 'HP'
        }, */
        {
            appendToChoice: <span>(Available 2025)</span>,
            dependentIds: [2,4],
            id: 1,
            name: 40,
            hullModel: 'Highfield-SP390',
            outboardColorIds: [1,2,3],
            unit: 'HP'
        },
        {
            appendToChoice: <span>(Available 2025)</span>,
            dependentIds: [2,4],
            id: 2,
            name: 70,
            hullModel: 'Highfield-SP460',
            outboardColorIds: [1,2,3],
            unit: 'HP'
        },
        {
            dependentIds: [1,2,3,4],
            id: 3,
            name: 100,
            hullModel: 'Highfield-SP660',
            outboardColorIds: [1,2,3],
            unit: 'HP'
        }
    ].reverse(),

    choiceDependency: 'model',

    clearOnChange: ['hullColor', 'outboardColor', 'trailer', 'moreInfo', 'fastCharging'],

    conditionalContent: (modelId) => {
        if (modelId && modelId > 1) {
            return (
                <p className='fm-studio__disclaimer text-center'>
                    Available 2025
                </p>
            );
        }

        return false;
    },

    fieldName: 'power',

    name: 'Power'
};


export default powerConfig;