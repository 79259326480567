// Components
import PageTitle from '../components/PageTitle';
import SectionHeader from '../components/global/SectionHeader';


export default function Home() {

  	return (

		<div
            className='fm-page'
            data-page='privacy-policy'
        >

            <PageTitle
                title='Privacy Policy'
                desc={'Learn how personal information is collected, used, and shared on www.fluxmarine.com'}
            />

            <section>

                <div className='fm-max-out'>

                    <div className='fm-section-header'>
                        <h1 className='fm-section-header__title'>
                            Flux Marine Privacy Policy
                        </h1>
                    </div>

                    <p>
                        This Privacy Policy describes how we handle personal information collected, used, 
                        and shared when a visitor or customers access <a className='text-decoration-underline' href='/'>www.fluxmarine.com</a>.
                    </p>

                </div>

            </section>

            <section className='fm-pp-section'>

                <div className='fm-max-out'>

                    <SectionHeader
                        title={'Section 1 - Transactional Information'}
                    />

                    <p>
                        What do you do with my information? When you purchase something from our store, as part of the buying and selling process, we collect the personal information you give us such as your name, address and email address.
                    </p>
                    <p>
                        When you browse our store, we also automatically receive your computer Internet protocol (IP) address in order to provide us with information that helps us learn about your browser and operating system.
                    </p>
                    <p>
                        Email marketing (if applicable):
                        <br />
                        With your permission, we may send you emails about our store, new products and other updates.
                    </p>

                </div>

            </section>

            <section className='fm-pp-section'>

                <div className='fm-max-out'>

                    <SectionHeader
                        title={'Section 2 - Consent'}
                    />

                    <p>
                        How do you get my consent? When you provide us with personal information to complete a transaction, verify your credit card, place an order, arrange for a delivery or return a purchase, we imply that you consent to our collecting it and using it for that specific reason only. If we ask for your personal information for a secondary reason, such as marketing, we will either ask you directly for your expressed consent, or provide you with an opportunity to say no. How do I withdraw my consent? After you opt-in, if you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use or disclosure of your information, at any time, by contacting us at <a className='text-decoration-underline' href='mailto:info@fluxmarine.com'>info@fluxmarine.com</a> or mailing us at: Flux Marine 500 Wood Street Bldg. 215, Bristol, RI 02908.
                    </p>

                </div>

            </section>

            <section className='fm-pp-section'>

                <div className='fm-max-out'>

                    <SectionHeader
                        title={'Section 3 - Disclosure'}
                    />

                    <p>
                        Do you disclose my information? We may disclose your personal information if we are required by law to do so or if you violate our Terms of Service.
                    </p>
                </div>

            </section>

            <section className='fm-pp-section'>

                <div className='fm-max-out'>

                    <SectionHeader
                        title={'Section 4 - Online Store'}
                    />

                    <p>
                        Our online store is hosted by Webflow and uses Stripe for payment processing.
                    </p>

                    <p>
                        You can read more about how Stripe and Webflow use your Personal Information here: <a className='text-decoration-underline' href='https://stripe.com/privacy' rel='noopener noreferrer' target='_blank'>https://stripe.com/privacy</a> and here: <a className='text-decoration-underline' href='https://webflow.com/legal/privacy' rel='noopener noreferrer' target='_blank'>https://webflow.com/legal/privacy</a>.
                    </p>

                    <p>
                        Payment: If you choose a direct payment gateway to complete your purchase, the online store transmits your credit card data. The store data is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS). Your purchase transaction data is stored only as long as is necessary to complete your purchase transaction. After that is complete, your purchase transaction information is deleted.
                        All direct payment gateways adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover.
                        PCI-DSS requirements help ensure the secure handling of credit card information by our store and its service providers.
                    </p>

                </div>

            </section>

            <section className='fm-pp-section'>

                <div className='fm-max-out'>

                    <SectionHeader
                        title={'Section 5 - Third-Party Services'}
                    />

                    <p>
                        In general, the third-party providers we use will only collect, use, and disclose your information to the extent necessary to allow them to perform the services they provide to us.
                    </p>

                    <p>
                        However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies in respect to the information we are required to provide to them for your purchase-related transactions.
                    </p>

                    <p>
                        For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers. You can opt out of targeted advertising by:
                        <br/><br/>
                        Facebook - <a className='text-decoration-underline' href='https://www.facebook.com/settings/?tab=ads' rel='noopener noreferrer' target='_blank'>https://www.facebook.com/settings/?tab=ads</a>
                        <br/>
                        Google - <a className='text-decoration-underline' href='https://www.google.com/settings/ads/anonymous' rel='noopener noreferrer' target='_blank'>https://www.google.com/settings/ads/anonymous</a>
                        <br/>
                        Bing - <a className='text-decoration-underline' href='https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads' rel='noopener noreferrer' target='_blank'>https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads</a>
                    </p>

                    <p>
                        In particular, remember that certain providers may be located in or have facilities that are located in a different jurisdiction than either you or us. So if you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located. As an example, if you are located in Canada and your transaction is processed by a payment gateway located in the United States, then your personal information used in completing that transaction may be subject to disclosure under United States legislation, including the Patriot Act. Once you leave our store website or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or our website's Terms of Service. When you click on links on our store, they may direct you away from our site. We are not responsible for the content or privacy practices of other sites and encourage you to read their privacy statements.
                    </p>

                </div>
            
            </section>

            <section className='fm-pp-section'>
            
                <div className='fm-max-out'>

                    <SectionHeader
                        title={'Section 6 - Security'}
                    />

                    <p>
                        To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed. If you provide us with your credit card information, the information is encrypted using secure socket layer technology (SSL) and stored with AES-256 encryption.  Although no method of transmission over the Internet or electronic storage is 100% secure, we follow all PCI-DSS requirements and implement additional generally accepted industry standards.
                    </p>

                </div>

            </section>

            <section className='fm-pp-section'>

                <div className='fm-max-out'>

                    <SectionHeader
                        title={'Section 7 - Do Not Track'}
                    />

                    <p>
                        Our website uses &quot;Cookies&quot; as data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit <a className='text-decoration-underline' href='http://www.allaboutcookies.org' rel='noopener noreferrer' target='_blank'>http://www.allaboutcookies.org</a>. Please note that we do not alter our Site's data collection and use practices when we see a Do Not Track signal from your browser. If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below. Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above.  Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.
                    </p>

                </div>

            </section>

            <section className='fm-pp-section'>

                <div className='fm-max-out'>

                    <SectionHeader
                        title={'Section 8 - Age of Consent'}
                    />

                    <p>
                        By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.
                    </p>

                </div>

            </section>

            <section className='fm-pp-section'>

                <div className='fm-max-out'>

                    <SectionHeader
                        title={'Section 9- Changes To This Privacy Policy'}
                    />

                    <p>
                        We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it. If our store is acquired or merged with another company, your information may be transferred to the new owners so that we may continue to sell products to you.
                    </p>

                </div>

            </section>

            {/* <section className='fm-pp-section'>

                <div className='fm-max-out'>

                    <SectionHeader
                        title={'Questions and Contact Information'}
                    />

                    <p>
                        Old site content was blank.
                    </p>

                </div>

            </section> */}

        </div>
  	)

}



