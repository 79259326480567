import BgVideoContainer from '../global/BgVideoContainer';

const videoJsOptions = {
    autoplay: true,
    controls: false,
    responsive: true,
    fluid: true,
    loop: false
};


export default function OutboardHero({ config }) {

	return (

        <div className='fm-outboard-hero'>

            <BgVideoContainer
                maxHeightOverride={'none'}
                options={{
                    ...videoJsOptions,
                    sources: [{
                        src: `${config.video}` || '',
                        type: 'video/mp4'
                    }]
                }}
                // onReady={handlePlayerReady}
            />

            <div className='fm-max-out fm-section-header'>
                <h1>{config.heading}</h1>
            </div>

        </div>

	)
}