import { useContext } from 'react';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from '../global/Button';

import { GlobalContext } from '../../contexts/GlobalContext';


export default function MissionBanner (props) {

    const { cdnUrl } = useContext(GlobalContext);

	return (

        <div className='fm-mission' style={{backgroundImage: `url(${cdnUrl}${props.backgroundImg})`}}>

            <span className='visually-hidden'>{props.backgroundAltText}</span>
   
            <div className='fm-mission__content-overlay h-100 d-flex align-items-center'>

                <div className='fm-mission__text-wrap fm-max-out d-lg-flex flex-lg-row justify-content-lg-between align-items-lg-center'>

                    <div className='fm-mission__top text-center text-lg-start'>

                        <h2 className='fm-fw-normal'>
                            {props.headingLine1}
                        </h2>

                        <p className='fm-mission__subhead'>
                            {props.subhead}
                        </p>

                    </div>

                    <div className='fm-mission__bottom d-flex flex-column align-items-center align-items-lg-end'>

                        <Button
                            label={props.ctaText}
                            theme={'secondary'}
                            url={props.ctaUrl}
                        />

                        <div className='fm-mission__social d-flex flex-row justify-content-between align-items-center'>

                            {props.social.map((platform) =>

                                <a
                                    className='fm-mission__social-link'
                                    href={platform.url}
                                    key={platform.url}
                                    rel='noopener noreferrer'
                                    target='_blank'
                                >
                                    <span className='visually-hidden'>{platform.name} icon links to Flux Marine's {platform.name} page.</span>
                                    <FontAwesomeIcon
                                        icon={`fa-brands fa-${platform.name.toLowerCase()}`}
                                        size='2xl'
                                    />
                                </a>

                            )}
                            
                        </div>

                    </div>

                </div>

            </div>

        </div>

	)
}
