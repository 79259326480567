import { Routes, Route } from "react-router-dom";

import StandardLayout from './layouts/StandardLayout';

import Home from './pages/Home';
import Outboards from './pages/Outboards';
import StdPackage from './pages/StdPackage';
import Inflatables from './pages/Inflatables';
/* import BoatPackages from './pages/BoatPackages'; */
import About from './pages/About';
import SeaTrialForm from './pages/form-pages/SeaTrialForm';
/* import DealerForm from './pages/form-pages/DealerForm';
import PartnerForm from './pages/form-pages/PartnerForm'; */
import Studio from './pages/Studio';
import FrequentlyAskedQuestions from './pages/FrequentlyAskedQuestions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Blog from './pages/Blog';
import TestPage from "./pages/TestPage";

import GlobalContextProvider from "./contexts/GlobalContext";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {

  return (

	<Routes>

		<Route path='/' element={
			<GlobalContextProvider>
				<StandardLayout />
			</GlobalContextProvider>
		}>

			<Route path='' element={<Home />} />

			<Route path='/outboards' element={<Outboards />} />

			<Route exact path='/packages/rigid-inflatables' element={<Inflatables />} />

			{/* <Route path='/packages' element={<BoatPackages />} /> */}

			<Route path='/packages/:package' element={<StdPackage />} />

			<Route path='/about' element={<About />} />

			{/* <Route path='/sea-trial' element={<SeaTrialForm />} /> */}

			{/* <Route path='/dealers' element={<DealerForm />} /> */}

			{/* <Route path='/partners' element={<PartnerForm />} /> */}

			<Route path='/configure' element={<Studio />} />

			<Route path='/faqs' element={<FrequentlyAskedQuestions />} />

			<Route path='/blog' element={<Blog />} />

			<Route path='/privacy' element={<PrivacyPolicy />} />

			<Route path='/test-page' element={<TestPage />} />

			{/* 
				<Route path="*">
					<NotFound/>
				</Route> 
			*/}
		</Route>


	</Routes>

  );
}

export default App;
