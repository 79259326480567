
import { Link } from 'react-router-dom';

// SVG Assets
import { useRef, useEffect, useState } from 'react';

export default function SectionHeader({ title, subtitle }) {

	// Footer Reference
    const footerRef = useRef(null);


	return (

		<div className='fm-section-header'>

            <h2 className='fm-section-header__title'>
                {title}
            </h2>

            {subtitle && (
                <p className='fm-section-header__subtitle'>
                    {subtitle}
                </p>
            )}

        </div>

	)
}
