import { useContext, useState } from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { GlobalContext } from '../../contexts/GlobalContext';


import Button from '../global/Button';

/* import SectionHeader from '../global/SectionHeader'; */


export default function Combinations({ title, combos, disclaimer }) {

    const { cdnUrl } = useContext(GlobalContext);

    const [expanded, setExpanded] = useState(combos.map((combo, i) => i === 0));

    const [expandedStdSpecs, setExpandedStdSpecs] = useState(combos.map(() => false));

    const [expandedExtdSpecs, setExpandedExtdSpecs] = useState(combos.map(() => false));

    const handleToggleClick = (index) => {
        setExpanded(prevState => {
            return prevState.map((curr, i) => 
                i === index ? !curr : curr
            );
        });
    }

    const handleToggleSpecsClick = (e, index, type) => {
        e.currentTarget.blur();
        
        const setStateFunc = type === 'std' ? setExpandedStdSpecs : setExpandedExtdSpecs ;

        setStateFunc(prevState => {
            return prevState.map((curr, i) => 
                i === index ? !curr : curr
            );
        });
    };

	return (

        <div className='fm-combos fm-max-out w-100'> {/* fm-max-out removed, replaced with w-100 */}

                {/* <SectionHeader
                    title={title}
                /> */}

                <div className='fm-combos__body d-flex flex-column flex-lg-row'>

                    {combos.map((combo, i) =>

                        <div
                            className='fm-combos__combination d-flex flex-column' /* text-center */
                            key={`${combo.outboard}-${i}`}
                        >

                            <img 
                                alt={combo.altText}
                                className={`fm-combos__combination-img img-fluid d-none d-lg-block`}
                                src={`${cdnUrl}${combo.image}`}
                            />

                            <div className='fm-combos__header'>

                                <h3 className='fm-combos__combination-name'>
                                    {combo.boat} + {combo.outboard}
                                </h3>

                                <button
                                    aria-controls={`combo${i+1}Image combo${i+1}Specs`}
                                    aria-expanded={'false'}
                                    className='fm-combos__toggle d-lg-none'
                                    data-bs-target={`.combo${i+1}-collapse`}
                                    data-bs-toggle='collapse'
                                    onClick={() => handleToggleClick(i)}
                                >
                                    <FontAwesomeIcon
                                        className='fm-faq__icon'
                                        icon={`fa-solid ${expanded[i] ? 'fa-minus' : 'fa-plus'}`}
                                        /* size='lg' */
                                    />

                                    <span className='visually-hidden'>
                                        {expanded[i] ? 'Collapse combination.' : 'Expand combination.'}
                                    </span>
                                </button>

                            </div>

                            <p className='fm-combos__combination-subhead text-center'>{combo.subhead|| <>&nbsp;</>}</p>

                            <div className={`fm-combos__container collapse ${i === 0 ? 'show' : ''} combo${i+1}-collapse`}>

                                <img 
                                    alt={`${combo.boat} + ${combo.outboard} rendering`}
                                    className={`fm-combos__combination-img img-fluid d-block mx-auto d-lg-none`}
                                    src={`${cdnUrl}${combo.image}`}
                                />


                                <div className='fm-combos__specs fm-fw-medium'>
                                    {combo.specs.map((spec, j) =>

                                        <div
                                            className='fm-combos__spec'
                                            key={`${spec.label}-${j}`}
                                        >
                                            <span className='fm-combos__spec-label'>
                                                {spec.label}:&nbsp;&nbsp;
                                            </span>
                                            <span className='fm-combos__spec-val'>
                                                {spec.value}
                                            </span>
                                        </div>

                                    )}
                                </div>

                                {/* <button
                                    aria-controls={`specs${i+1}-standard-collapse`}
                                    aria-expanded={'false'}
                                    className='fm-combos__specs-btn d-block'
                                    data-bs-target={`#specs${i+1}-standard-collapse`}
                                    data-bs-toggle='collapse'
                                    onClick={(e) => handleToggleSpecsClick(e, i, 'std')}
                                > 
                                    <span>Standard Battery</span>
                                    <FontAwesomeIcon
                                        className='fm-faq__icon'
                                        icon={`fa-solid ${expandedStdSpecs[i] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                                    />
                                </button> */}

                                {/* <div
                                    className='fm-combos__specs--collapsible collapse fm-fw-medium'
                                    id={`specs${i+1}-standard-collapse`}
                                >
                                    <div className='fm-combos__specs-container'>
                                        {combo.standardSpecs.map((spec, k) =>
                                            <div
                                                className='fm-combos__spec'
                                                key={`${spec.label}-${k}`}
                                            >
                                                <span className='fm-combos__spec-label'>
                                                    {spec.label}:&nbsp;&nbsp;
                                                </span>
                                                <span className='fm-combos__spec-val'>
                                                    {spec.value}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div> */}

                            </div>

                            {combo.cta && (
                                <Button
                                    {...combo.cta}
                                    theme={combo.cta.theme || 'accent'}
                                    url={'/configure'}
                                />
                            )}

                        </div>
                    
                    )}

                </div>

                <p className='disclaimer text-center text-lg-end'>
                    &#42;&nbsp;{disclaimer}                            
                </p>
                
        </div>

	);
}

