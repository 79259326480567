import { useContext } from 'react';

import { GlobalContext } from '../../contexts/GlobalContext';

export default function BgImgContainer({ altText, children, img, mask = false, parallax = false }) {

    const { cdnUrl } = useContext(GlobalContext);

    const backgroundStyles = {
        backgroundImage: `url(${cdnUrl}${img.image})`
    };

    const SectionContent = (<>{children}</>);

	return (

        <div
            className={`fm-bg-img-container fm-bg-img-container--${mask ? 'mask' : 'no-mask'}${parallax ? ' fm-bg-img-container--parallax' : ''}`} /* fm-full-height */
            style={backgroundStyles}
        >

            { (altText || img.altText) && (
                <span className='visually-hidden'>{altText || img.altText}</span>
            )}

            { mask ? 
                (
                    <div className='fm-bg-img-container__mask'>
                        {SectionContent}
                    </div>
                )
                :
                (SectionContent)
            }

        </div>

	)

}
