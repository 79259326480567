// Configuration Object: Hubspot Form IDs
const formsConfig = {
    contactUs: '4455314e-eabe-44ff-9890-533776df18f1',
    seaTrial: 'efdaba5f-ea7d-4e65-a684-c6ac627b3cfd',
    dealers: 'f0849c76-878b-4bbf-8d8c-91aaa366947d',
    partners: '3d52b40c-6e65-4197-9f72-cbd68e887ce9',
    subscribe: '3da1ea82-ad5e-45b6-be82-42611b6332aa'
};


// Configuration Object: Meta Descriptions for Full Form Pages
const formPageMetaDescriptions = {
    seaTrial: 'Sign up to experience Flux Marine\'s 100% electric outboards in person.',
    dealers: 'Contact us if you are interested in becoming part of Flux Marine\'s dealer network.',
    partners: 'Contact us if you are an OEM or other boating-industry provider and you think we would benefit from partnering.'
}

export { formsConfig, formPageMetaDescriptions };