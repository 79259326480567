const softwareConfig = {

    // Lowercase version of label below (multi-word labels need an id with hyphens in place of spaces)
    id: 'software',

    // Appears along the sticky bottom bar
    label: 'Software',

    imagePanel: {

        altText: 'Outboards Charging Image',

        image: '/outboards/software/software_full_size.jpg'

    },

    contentPanel: {

        // Image Source
        image: '/outboards/software/software_mobile_app_v2.png',

        // Alternate text describing image contents (for use with a screenreader)
        altText: 'Two side-by-side Flux Marine mobile app renderings on mobile devices.',

        // Heading
        title: 'Mobile Integration',

        // Must remain inside parenthesis and <></>; use <br /> for line breaks
        body: (<>
            Stay connected with your boat through our user-friendly mobile app. Remotely check charging status and motor health. Keep a record of past trips and energy usage even when your boat is loaned to friends or family.
            <br/>
            <br />
            Our dedicated fleet management page offers a centralized hub to effortlessly monitor the location and trip history of your entire fleet, streamlining the management process.
        </>)
    }

};

export default softwareConfig;