import { useContext, useState } from 'react';

import Button from '../global/Button';

import { GlobalContext } from '../../contexts/GlobalContext';


export default function ProductLineup (props) {

    const { outboardsImg, outboardsTitle, packagesImg, packagesTitle, title } = props;

    const { cdnUrl } = useContext(GlobalContext);

	return (

        <div className='fm-lineup-inner fm-max-out'>

            <div className='fm-lineup__wrapper d-flex flex-column flex-lg-row'>

                <div className='fm-lineup__left fm-lineup__outboard d-flex flex-column align-items-center'>
                    <h4 className='text-center'>{outboardsTitle}</h4>

                    <div className='fm-carousel__slide-img'>
                        <img 
                            alt={'Outboards image'}
                            className='img-fluid'
                            src={`${cdnUrl}${outboardsImg}`}
                            width={688}
                            height={387}
                        />
                    </div>

                    <div className='fm-lineup__buttons d-flex flex-column flex-sm-row flex-lg-column flex-xl-row'>

                        <Button
                            label={'Configure'}
                            stateObj={{
                                modelId: 4
                            }}
                            url={'/configure'}
                        />

                        <Button
                            label={'Learn More'}
                            theme={'secondary'}
                            url={'/outboards'}
                        />

                    </div>

                </div>

                <div className='fm-lineup__right d-flex flex-column align-items-center'>

                    <h4 className='text-center'>{packagesTitle}</h4>

                    <div className='fm-carousel__slide-img'>
                        <img 
                            alt={'Boat Packages image'}
                            className='img-fluid'
                            src={`${cdnUrl}${packagesImg}`}
                            width={688}
                            height={387}
                        />
                    </div>

                    <div className='fm-lineup__buttons d-flex flex-column flex-sm-row flex-lg-column flex-xl-row'>

                        <Button
                            label={'Configure'}
                            url={'/configure'}
                        />

                        <Button
                            label={'Learn More'}
                            theme={'secondary'}
                            url={'/packages/dual-console'}
                        />

                    </div>

                </div>

            </div>

        </div>
    );

}