import { useEffect, useRef, useState } from 'react';

const useScrollSectionData = () => {
    const [scrollSections, setScrollSections] = useState([]);
  
    useEffect(() => {
        const scrollSectionElements = Array.from(
            document.querySelectorAll('.fm-scrollable-section')
        );

        const dataArr = [];

        scrollSectionElements.forEach((elem, i) => {
            const { id } = elem;
            dataArr.push({
                id: id,
                label: elem.getAttribute('data-scroll-label')
            });
        });
  
        setScrollSections(dataArr);
    }, []);
  
    return { scrollSections };
};

const useIntersectionObserver = (setActiveId) => {
	const sectionsRef = useRef({});

	useEffect(() => {

		const callback = (sections) => {
			sectionsRef.current = sections.reduce((map, sectionElement) => {
				map[sectionElement.target.id] = sectionElement;
				return map;
			}, sectionsRef.current);

			const visibleSections = [];
			Object.keys(sectionsRef.current).forEach((key) => {
				const sectionElement = sectionsRef.current[key];
				if (sectionElement.isIntersecting) {
					visibleSections.push(sectionElement);
				}
			});

			const getIndexFromId = (id) => {
				// scrollSections??
				sections.findIndex((section) => section.id === id);
			};

			if (visibleSections.length === 1) {
				setActiveId(visibleSections[0].target.id);
			}
			else if (visibleSections.length > 1) {
				const sortedVisibleSections = visibleSections.sort(
					(a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
				);
				// setActiveId(sortedVisibleSections[0].target.id);
				setActiveId(sortedVisibleSections[1].target.id);
			}
		};
	
		// -65px: Cover the sticky header height plus one pixel so nothing behind it counts as in view
		//  -50%: Cover the bottom 50% of the view so a section isn't considered visible until it's close to the middle
		const observer = new IntersectionObserver(callback, {
			rootMargin: '-65px 0px -50% 0px',
			/* threshold: 0.5 // half of the item height */
		});

		const scrollSections = Array.from(document.querySelectorAll('.fm-scrollable-section'));

		scrollSections.forEach((element) => observer.observe(element));

		return () => observer.disconnect();

	}, [setActiveId]);
	
};

export default function StickyButtons({ children }) {
	const [activeId, setActiveId] = useState();
	const [show, setShow] = useState(false);
  	const { scrollSections } = useScrollSectionData();

	let targetSection;

  	useIntersectionObserver(setActiveId);	

	const handleScroll = (e, id) => {
		e.preventDefault();
		const elem = document.getElementById(id);
		window.scrollTo({
			top: elem.getBoundingClientRect().top + window.pageYOffset - 64,
			behavior: 'smooth'
		});
	};




	/*
        On scroll:
            - Check the position of the bottom of the target <section> or <div> element relative to the window
            - If it's bottom position has reached the bottom of the header, update state to trigger show class on sticky footer
            - Otherwise, update state to trigger removal of the show class on the sticky footer
    */
	const handleScrollListener = () => {
		const headerHeight = document.querySelector('.fm-header').clientHeight;

		if (!show && ( (targetSection.getBoundingClientRect().bottom - 32) <= headerHeight )) {
			setShow(true);
		} else {
			setShow(false);
		}
	};

	useEffect(() => {
        // Retrieve the page type from data attribute
        const page = document.querySelector('.fm-page');

		// Retrieve optional data attribute containing ID of scroll element after which to fill in the header
		const dataScrollTarget = page.getAttribute('data-sticky-footer');
        targetSection = document.querySelector(dataScrollTarget);

		// Add scroll listener & handler
		if (targetSection) {
			document.addEventListener('scroll', handleScrollListener);
		}
    }, []);

    return (
      
      <div className={`fm-sticky-buttons${show ? ' fm-sticky-buttons--show' : ''}`}>

        <div className={`fm-sticky-buttons__inner fm-max-out d-flex flex-row justify-content-center justify-content-md-end justify-content-lg-${scrollSections ? 'between' : 'end'} align-items-center`}>

			{scrollSections && (

				<ul className='fm-scroll-list d-none d-lg-block'> {/* d-xl-flex align-items-xl-stretch */}

					{scrollSections.map((item) =>
						<li className={`fm-scroll-list__item ${item.id === activeId ? 'fm-scroll-list__item--active' : ''} d-inline-block text-center`} key={item.id}>
							<a className='fm-scroll-list__link' href={`#${item.id}`} onClick={(e) => handleScroll(e, item.id)}>
								{item.label}
							</a>
						</li>
					)}

				</ul>
			)}
		
			<div className='fm-sticky-buttons__btn-wrapper d-flex'>
				{children}
			</div>

        </div>

      </div>
    )
}