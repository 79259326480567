const dashboardConfig = {

    // Image Source
    image: '/packages/inflatable/software_garmin_v2.png',

    // Alternate text describing image contents (for use with a screenreader)
    altText: 'A Flux Marine user experience on a Garmin Marine dashboard.',

    // Heading
    title: 'Command Your Experience',

    // Must remain inside parenthesis and <></>; use <br /> for line breaks
    body: (<>
        Take complete control at the helm with live updates on the integrated Garmin chartplotter.
        <br /><br />
        Monitor your speed, battery life, and range with the tap of a finger using the Flux dashboard.
        <br /><br />
        Our throttle is built to pair with the Flux dashboard and mobile app to provide a smoother, smarter ride.
    </>),

    // Button Text (optional)
    ctaText: 'More Dashboard Info',

    // Button URL (optional)
    ctaUrl: '/outboards',

    // Scroll to Outboards page's Software section by its index (optional)
    stateObj: { scrollTo: 'software' }

};

export default dashboardConfig;