// Import single source of social links
import socialLinks from "../social-links";

// Configuration Object
const missionBannerConfig = {

    backgroundAltText: 'Lake George, NY in black and white',

    backgroundImg: '/homepage/mission_banner.png',

    // Large Text Content (Must remain inside <></> brackets; use <br /> to break the line)
    headingLine1: <>Revolutionizing Marine Propulsion <br className='d-none d-sm-inline' />with Power, Endurance and Reliability.</>,

    // Small Text Content
    subhead: 'Learn how our mission inspires our technology approach.',

    // Standard Button Text and URL
    ctaText: 'About Us',
    ctaUrl: '/about',

    // Social Platform links (DO NOT TOUCH, edit social platforms directly in /config/social-links.js)
    social: socialLinks

};
  
export default missionBannerConfig;