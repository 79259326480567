/* import { useRef, useEffect, useState } from 'react'; */

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SectionHeader from '../../components/global/SectionHeader';
import Button from '../../components/global/Button';


export default function SpecsTable({ data }) {

    const names = data.map((model) => model.name);
    const hullTypes = data.map((model) => model.hullType);
    const powerRatings = data.map((model) => model.powerSpecs.rating);
    const activeRanges = data.map((model) => model.activeRange);
    const accRatings = data.map((model) => model.powerSpecs.acceleration);
    const speeds = data.map((model => model.speeds));
    const whatsIncluded = data.map((model) => model.included);
    const available = data.map((model) => model.available);


	return (
        <>
            <div className='fm-specs-table'>

                <div className='fm-specs-table__header'>
                    <SectionHeader
                        title='Detailed Specs'
                    />
                </div>

                <div className='fm-specs-table__tables d-flex flex-column flex-xl-row'>

                    <div className='fm-specs-table__wrapper'>

                        <table className='fm-table fm-specs-table__table table table-bordered text-center'>
                            
                            <thead className='table-light'>
                                
                                <tr>
                                    <th scope="col"></th>
                                    
                                    {/* Removed the .slice(2) before reversing to get the whole set */}
                                    {names.reverse().map((modelName) =>
                                        <th key={modelName} scope="col">{modelName}</th>
                                    )}

                                </tr>

                            </thead>

                            <tbody>

                                <tr>

                                    <th scope="row">
                                        Demo Boat For Performance Testing
                                    </th>

                                    {hullTypes.reverse().map((type, i) => 
                                        <td key={`${type}-${i}`}>{type}</td>
                                    )}

                                </tr>

                                <tr>

                                    <th scope="row">Power Rating:</th>

                                    {powerRatings.reverse().map((rating, i) => 
                                        <td key={`${rating}-${i}`}>{rating} HP</td>
                                    )}

                                </tr>

                                <tr>

                                    <th scope="row">Acceleration Power:</th>

                                    {accRatings.reverse().map((rating, i) => 
                                        <td key={`${rating}-${i}`}>{rating} HP</td>
                                    )}

                                </tr>

                                <tr>
                                    
                                    <th scope="row">Range at Cruising Speed</th>
                                    
                                    {activeRanges.reverse().map((obj, i) => 
                                        <td key={i}>

                                            <ul>

                                                { Object.keys(obj).map((battery, j) =>
                                                    <li key={`${battery}-${j}`}>
                                                        {/* {battery.charAt(0).toUpperCase() + battery.slice(1)} Battery: {obj[battery]}mi */}
                                                        {obj[battery]}mi
                                                    </li>
                                                )}

                                            </ul>

                                        </td>
                                    )}

                                </tr>

                                <tr>

                                    <th scope="row">Cruising Speed / Top Speed:</th>

                                    {speeds.reverse().map((speed, i) => 
                                        <td key={`${speed}-${i}`}>{speed.cruising}mph / {speed.top}mph</td>
                                    )}

                                </tr>

                                <tr>
                                    <th scope="row">Includes:</th>
                                    
                                    {whatsIncluded.reverse().map((arr, i) => 
                                        <td key={i}>
                                            
                                            { arr.map((text, j) =>
                                                <span key={text}>
                                                    {text}
                                                    {j < arr.length - 1 ? '; ' : ''}
                                                </span>
                                            )}

                                        </td>
                                    )}
                                </tr>

                                <tr>
                                    <th scope="row">Available:</th>
                                    
                                    {available.reverse().map((a, i) => 
                                        <td key={i}>
                                            {a}
                                        </td>
                                    )}
                                </tr>

                                <tr className='fm-specs-table__btn-row'>
                                    <td colSpan={1}></td>

                                    <td>

                                        <Button
                                            customClass='fm-btn--reserve'
                                            label={'Configure'}
                                            theme='secondary-accent'
                                            url={'/configure'}
                                            stateObj={{ modelId: 4 }}
                                        />

                                    </td>

                                    <td>

                                        <Button
                                            customClass='fm-btn--reserve'
                                            label={'Configure'}
                                            theme='secondary-accent'
                                            url={'/configure'}
                                            stateObj={{ modelId: 4 }}
                                        />

                                    </td>

                                    <td>
                                        <Button
                                            customClass='fm-btn--reserve'
                                            label={'Reserve'}
                                            theme='secondary-accent'
                                            url={'/configure'}
                                            stateObj={{ modelId: 4 }}
                                        />
                                    </td>

                                    {/* <td>
                                        <Button
                                            customClass='fm-btn--reserve'
                                            label={'Reserve'}
                                            theme='secondary-accent'
                                            url={'/configure'}
                                            stateObj={{ modelId: 4 }}
                                        />
                                    </td> */}

                                </tr>

                            </tbody>

                        </table>

                    </div>

                    <div className='fm-specs-table__more d-flex justify-content-end align-items-center d-xl-none'>
                        <span>Scroll for More&nbsp;</span>
                        <FontAwesomeIcon icon='fa-solid fa-chevron-right' />
                    </div>

                </div>

            </div>

            {/* Bottom Disclaimers */}
            <div className='fm-specs-table__disclaimers'>

                <p className='disclaimer text-center text-md-start'>
                    &#42;&nbsp;Performance specifications and price may vary based on configuration and are subject to change.
                </p>
                
                <p className='disclaimer text-center text-md-start'>
                    &#42;&nbsp;Range extends an additional 10 miles at harbor speeds. Top and Cruising Speeds dependent on passenger count, sea state, and trim.
                </p>

            </div>
            {/* END Bottom Disclaimers */}

        </>

	)
}