// Import modular config files
import heroConfig from './hero-config';
import technologyConfig from './technology-config';
import contentPanelsConfig from './content-sections-config';
import chargingConfig from './charging-config';
import softwareConfig from './software-config';
import futureConfig from './future-config';
import specsConfig from './specs-config';


// Configuration Object
const outboardsConfig = {

    metaDesc: '100% Electric Outboards build from the ground up. Available in 15, 40, 70, and 100HP, and in curated electric boat packages. Pre-order yours now.',

    hero: heroConfig,

    tech: technologyConfig,

    charging: chargingConfig,

    software: softwareConfig,

    future: futureConfig,

    // Content areas with Images and Text
    contentPanels: contentPanelsConfig,

    tableSpecs: specsConfig

};

  
export default outboardsConfig;