import { useContext, useState } from 'react';

import { Link } from 'react-router-dom';

import { GlobalContext } from '../../contexts/GlobalContext';

import Button from '../global/Button';


const Spec = ({label, val}) => {
    return (
        <div className='fm-model-panel__spec text-center'>

            <span className='fm-model-panel__spec-val d-block'>{val}</span>

            <span className='fm-model-panel__spec-label d-block'>{label}</span>

        </div>
    );
};


export default function ModelPanel(props) {

    const { cdnUrl } = useContext(GlobalContext);

    const {label, contPower, accPower, colors, cta} = props;

    const [activeColor, setActiveColor] = useState(colors.filter((color) => color.default)[0]);

    const handleColorClick = (colorObj) => {
        setActiveColor(colorObj);
    }

	return (
        <div className='fm-model-panel d-flex justify-content-center'>

            <div>

                {/* <img
                    alt={label.text}
                    className={`fm-model-panel__label-img img-fluid`}
                    src={`${cdnUrl}${label.img}`}
                    width={418}
                    height={154}
                /> */}

                <h3 className='fm-model-panel__label'>{label.text}</h3>

                <div className='fm-model-panel__container d-flex flex-row'>

                    <div className='fm-model-panel__specs'>

                        <Spec
                            label={'Continuous Power'}
                            val={`${contPower} HP`}
                        />

                        <Spec
                            label={'Acceleration Power'}
                            val={`${accPower} HP`}
                        />

                    </div>

                    <div className='fm-model-panel__img-container'>
                        <img
                            alt={`${activeColor.color} Outboard Rendering`}
                            className={`fm-model-panel__img img-fluid`}
                            src={`${cdnUrl}${activeColor.img}`}
                            // width={}
                            // height={}
                        />
                    </div>

                    {colors.length > 1 && (
                        <div className='fm-model-panel__color-btns d-flex flex-column'>

                            {colors.map((colorObj) =>

                                <button
                                    aria-label={`View ${colorObj.color} Outboard Rendering`}
                                    className={`fm-model-panel__color-btn fm-model-panel__color-btn--${colorObj.color}${colorObj === activeColor ? ' fm-model-panel__color-btn--active': ''}`}
                                    key={`${label.text} ${colorObj.color} button`}
                                    onClick={() => handleColorClick(colorObj)}
                                >
                                </button>

                            )}

                        </div>
                    )}

                </div>

                <div className='fm-model-panel__bottom d-flex flex-row justify-content-center'>

                    <Button
                        {...cta}
                        url={'/configure'}
                    />

                </div>

            </div>

        </div>
	);
}
