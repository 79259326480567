import { useContext } from 'react';

import { GlobalContext } from '../../contexts/GlobalContext';


export default function Preview({ previewObj }) {

    const { cdnUrl } = useContext(GlobalContext);

    const {altText, image, loa, model, price, range, topSpeed} = previewObj;

    return (
        <div className='fm-studio__preview-inner h-100 d-flex flex-column'>
                            
            <h1 className='fm-studio__title text-center'>Configure Now:</h1>

            <div className='fm-studio__img-wrap d-flex justify-content-center align-items-center'>
                {image ?
                    (<img
                        alt={altText}
                        className='img-fluid'
                        src={`${cdnUrl}${image}`}
                        /* width={822}
                        height={463} */
                    />)
                    :
                    (<div className='fm-studio__no-selection'>
                        <img
                            alt='Flux Marine Teal Line Image'
                            className='img-fluid'
                            src={`${cdnUrl}/studio/teal-line.png`}
                        />
                        <p>Select a Model to See a Preview.</p>
                    </div>)
                }
            </div>

            {model !== 4 && (

                <div className='fm-studio__specs'>

                    <div className='fm-studio__spec'>
                        <div>
                            <span className='fm-studio__spec-val'>{loa}</span>
                        </div>
                        <div>
                            <span className='fm-studio__spec-label'>LOA</span>
                        </div>
                    </div>

                    <div className='fm-studio__spec'>
                        <div>
                            <span className='fm-studio__spec-val'>{range}</span>
                            <span className='fm-studio__spec-unit'>mi</span>
                        </div>
                        <div>
                            <span className='fm-studio__spec-label'>Est. Cruising Range</span>
                        </div>
                    </div>

                    <div className='fm-studio__spec'>
                        <div>
                            <span className='fm-studio__spec-val'>{topSpeed[0]}</span>
                            <span className='fm-studio__spec-unit'>mph</span>
                        </div>
                        <div>
                            <span className='fm-studio__spec-label'>Top Speed</span>
                        </div>
                    </div>

                    <div className='fm-studio__spec fm-studio__spec--price'>
                        <div>
                            <span className='fm-studio__spec-val'>
                                {price === 'Inquire' ? 'Inquire' : `$${price.toLocaleString()}`}
                            </span>
                        </div>
                        <div>
                            <span className='fm-studio__spec-label'>Price</span>
                        </div>
                    </div>

                </div>

            )}

            {/*
                For a Model ID of 3 (Outboard Only), don't show specs.
                This empty spec with non-breaking spaces ensures no layout shift while the specs are hidden.
            */}
            {model == 4 && (
                <div className='fm-studio__outboard-disclosure text-center'>

                    <p>Outboard repowers, as well as FM40 and FM70 boat packages, will not be available until 2025.</p>
                    <p>FM70 and FM100 other boat packages may be available sooner.</p>
                    <p>Continue configurating to reserve yours.</p>
                </div>
            )}

        </div>
    );
}