// Configuration Object
const heroConfig = {
    largeText: (<>Building the World's Best Boating Experiences</>),
    image: '/about/hero_new.jpg',
    imageDescription: 'Black Electric Outboard with Flux logo visible.',
    textBlocks: [
        'Boating is a passion, a career, a lifestyle, and so much more. As a boating company based in the ocean state (Rhode Island), we are fundamentally connected to the water, and we fully embrace the growing global consciousness surrounding renewable energy.',
        'We believe performance and sustainability do not have to be mutually exclusive. It is essential to preserve the excitement and joy of being out on the water while simultaneously offering the safety, convenience, and affordability that boaters have come to rely on and treasure. We took a clean slate approach to redesigning the marine propulsion system with a renewed focus on performance, reliability, and sustainability. With these principles in mind, we are not merely following a trend; we\'re defining it.',
        'Flux Marine\'s mission is to advance electric propulsion technology to offer the most exceptional boating experiences in the world so you can Chase Any Horizon. This mission is guided by three core pillars: empowering our employees to innovate and drive change, prioritizing the customer experience, and demonstrating stewardship towards our environment and community.',
        'To us, \"Chase Any Horizon\" embodies this objective: dismantling barriers, exploring uncharted territories, and uncovering untapped potential. Our commitment is to continually push the boundaries of how people move on the water. We are delighted to have you join us on this journey.'
    
    ]
};

  
export default heroConfig;