import { createRef, useEffect, useRef, useState } from 'react';

import { useLocation, useSearchParams } from 'react-router-dom';

/* import createScrollSnap from 'scroll-snap'; */

// Components
import PageTitle from '../components/PageTitle';
import AboutUsHero from '../components/about-components/AboutUsHero';
import ContactUsHubspot from '../components/about-components/ContactUsHubspot';
import ContactUs from '../components/about-components/ContactUs';
import Careers from '../components/about-components/Careers';

// Configuration Files
import heroConfig from '../config/about/hero-config';
import contactUsConfig from '../config/about/contact-us-config';

import scrollUtil from '../lib/scroll-utility';


export default function About() {

    // Retrieve state passed from Link element (for auto-scroll to Contact Us)
    const location = useLocation();
    // Leftover search param functionality for Contact Us link in offscreen navigation
    const [searchParams, setSearchParams] = useSearchParams();

    // Section References
    const scrollableSections = ['Hero', 'ContactUs', 'Careers'];
    const sectionRefs = useRef([]);
    sectionRefs.current = scrollableSections.map((section, i) => sectionRefs.current[i] ?? createRef());

    useEffect(() => {
        const locState = location.state;
        if ((locState && locState.scrollTo)) {
            setTimeout(() => {
                scrollUtil.scrollToSection(sectionRefs.current[locState.scrollTo]);
            }, 350);
        }

        if ((searchParams && searchParams.get('scrollTo'))) {
            setTimeout(() => {
                scrollUtil.scrollToSection(sectionRefs.current[searchParams.get('scrollTo')]);
            }, 350);
        }
    }, [location, searchParams]);


  	return (

		<div
            className='fm-page'
            data-page='about'
            // The selector (class or id) of the text element relative to which the header background will fill in
            data-header-fill='.fm-about-hero__title'
        >

            <PageTitle
                title='About Us'
                canonical='about'
                desc='Flux Marine produces electric propulsion systems along with boat packages and software systems to revolutionize the boating experience.'
            />

            {/* Hero Section */}
            <section className='fm-full-height p-0' ref={sectionRefs.current[0]}>
                <AboutUsHero config={heroConfig} />
            </section>


            {/* Container: Contact Us & Form Links Sections */}
            <div className='fm-full-height d-flex flex-column' ref={sectionRefs.current[1]}>

                {/* Contact Us Section */}
                <section className='fm-section-contact' id='contact-us'>
                    {/* <ContactUsHubspot
                        config={contactUsConfig}
                        sectionRef={sectionRefs.current[1]}
                    /> */}
                    <ContactUs
                        sectionRef={sectionRefs.current[1]}
                        inquiry={location.state && location.state.inquiry}
                        msg={location.state && location.state.msg} 
                    />
                </section>
            
            </div>
            {/* END Container: Contact Us & Form Links Sections */}


            {/* Careers Section */}
            <section
                className='p-0'
                ref={sectionRefs.current[2]}
            >
                <Careers />
            </section>

        </div>
  	)

}