import dashboardConfig from './dashboard-config';
import hullConfig from './hull-config';
import outboardConfig from './outboard-config';
import batteryConfig from './battery-config';
/* import mosaicConfig from './mosaic-config'; */

const dualConsoleConfig = {
    
    name: 'Dual Console',

    metaDesc: 'Pre-order your 100% electric 22-foot dual console boat package. The perfect electric solution for a full day of boating , curated to you.',

    altText: 'Rendering of a Flux Marine Dual Console bowrider electric Boat.',

    image: '/packages/dual-console/rendering.png',

    hero: {

        img: {

            altText: 'Electric Dual Console boat on calm waters from the side.',
    
            image: '/packages/dual-console/dual_console_hero.jpg'
    
        },

        /* specs: [
            {
                label: 'Continuous Power',
                val: 100,
                unit: 'HP'
            },
            {
                label: 'Estimated Cruising Range',
                val: '25 - 35',
                unit: 'mi'
            },
            {
                label: 'Top Speed',
                val: 30,
                unit: 'mph'
            },
            {
                label: 'Starting Price',
                val: '$120,000',
                unit: ''
            }
        ], */

    },

    overviewSpecs: [
        {
            label: 'Continuous Power',
            value: 100,
            unit: 'HP'
        },
        {
            label: 'Est. Cruising Range',
            value: '25-30',
            unit: 'mi'
        },
        {
            label: 'Top Speed',
            value: 30,
            unit: 'mph'
        },
        {
            label: 'Starting Price',
            value: '$120,000',
            unit: ''
        }
    ],

    videoDesc: 'A video compilation of Flux Marine Electric Dual Console at speed and rest.',

    videoSrc: '/packages/dual-console/DC-Video.mp4',

    startingPrice: '120,000', // $

    dashboard: dashboardConfig,

    hull: hullConfig,

    outboard: outboardConfig,

    battery: batteryConfig,

    /* mosaic: mosaicConfig, */

    disclaimer: 'Metrics subject to change in production system.'

};

export default dualConsoleConfig;