import { useContext } from 'react';

import { GlobalContext } from '../../contexts/GlobalContext';

import SectionHeader from './SectionHeader';

// Utilities
import useOnScreen from '../../lib/element-on-screen';

const ImageContent = ({altText, src, title, titleRef}) => {
    const { cdnUrl } = useContext(GlobalContext);

    return (
        <div className='fm-scroll-panel__img-wrap'>
            <div
                className={`fm-scroll-panel__img h-100`}
                style={{backgroundImage: `url(${cdnUrl}${src})`}}
            >
                <span className='visually-hidden'>
                    {altText}
                </span>

                <div className='fm-scroll-panel__title-wrap' ref={titleRef}>
                   {/*  <SectionHeader
                        title={title}
                    /> */}
                </div>

            </div>
        </div>
    );
};

const TextContent = ({ body, buttonConfig, disclaimer, subhead, title }) => {
    return (
        <div className='fm-scroll-panel__txt-wrap'>

            <div className='fm-scroll-panel__txt-inner text-center text-lg-start h-100 d-flex flex-column justify-content-lg-center'>

                <div>

                    <SectionHeader
                        title={title}
                        subtitle={subhead}
                    />

                    <div className='fm-scroll-panel__txt-body'>
                        {body}
                    </div>

                </div>

            </div>

        </div>
    );
};


export default function ScrollPanel({ altText = 'Large Flux Marine image', body, image, subhead, textPosition = 'right', title }) {

    /* const [ panelRef, panelIsVisible ] = useOnScreen({
        rootMargin: '-64px 0px -68px 0px'
    }, true); */

    /* const panelRef = useRef();
    const [start, setStart] = useState(false);
    const [end, setEnd] = useState(true); */

    const [ titleRef, titleIsVisible ] = useOnScreen({
        rootMargin: '-64px 0px -108px 0px',
        threshold: 1.0
    }, true);

    const imageTile = (
        <ImageContent
            altText={altText}
            src={image}
            title={title}
            titleRef={titleRef}
        />
    );

    const textTile = (
        <TextContent
            body={body}
            subhead={subhead}
            title={title}
        />
    );

    /* const handlePageScroll = () => {

        // Reference the height of the window, header and sticky bottom bar
        const windowHeight = window.innerHeight,
            headerHeight = document.querySelector('.fm-header').clientHeight,
            stickyBarHeight = document.querySelector('.fm-sticky-buttons').clientHeight;
        
        // Reference the distance of the top/bottom of the component instance from the top of the window and its height
        const sectionTop = panelRef.current.offsetTop,
            componentHeight = panelRef.current.clientHeight,
            sectionEnd = sectionTop + componentHeight;

        if (
            !start 
            && (window.scrollY + headerHeight) >= sectionTop
            && ( (sectionEnd - window.scrollY) >= (windowHeight - stickyBarHeight) ) 
        ) {
            setStart(true);
        }
        
        else {
            setStart(false);
        }

        if ( 
            ( (window.scrollY + headerHeight) < sectionEnd )
            && ( (sectionEnd - window.scrollY) < (windowHeight - stickyBarHeight) )
        ) {
            setEnd(true);
        } else {
            setEnd(false);
        }
    }; */

    // Add page scroll listener & handler
    /* useEffect(() => {
        document.addEventListener('scroll', handlePageScroll);
    }, []);
 */

	return (

		<div
            className={`fm-scroll-panel${titleIsVisible ? ' fm-scroll-panel--animate' : ''} d-flex flex-column flex-lg-row`} /* ${start ? ' fm-scroll-panel--lock' : ''}${end ? ' fm-scroll-panel--unlock' : ''} */
            /* ref={panelRef} */
        >

            {/* Left Side Content */}
            {textPosition === 'left' ? textTile : imageTile}
            {/* END Left Side Content */}

            {/* Right Side Content */}
            {textPosition === 'left' ? imageTile : textTile}
            {/* END Right Side Content */}

            {/* <div className='fm-scroll-panel__ref-elem' ref={panelRef}>&nbsp;</div> */}

        </div>

	)
}
