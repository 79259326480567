import { useEffect, useMemo, useRef, useState } from 'react';

import { useLocation } from 'react-router-dom';

// Components
import PageTitle from '../components/PageTitle';
import SectionHeader from '../components/global/SectionHeader';
import OutboardHero from '../components/outboard-components/OutboardHero';
import BgVideoContainer from '../components/global/BgVideoContainer';
import ModelPanel from '../components/outboard-components/ModelPanel';
import ScrollPanel from '../components/global/ScrollPanel';
import ContentPanel from '../components/global/ContentPanel';
import SpecsTable from '../components/outboard-components/SpecsTable';
import BgImgContainer from '../components/global/BgImgContainer';
import StickyButtons from '../components/global/StickyButtons';
import Button from '../components/global/Button';

import scrollUtil from '../lib/scroll-utility';

import outboardsConfig from '../config/outboards/outboards-config';

const videoJsOptions = {
    autoplay: true,
    controls: false,
    responsive: true,
    fluid: true,
    loop: false
};



export default function TestPage() {

    const page = outboardsConfig;

  	return (
		<div
            className='fm-page fm-page--sticky-footer'
            data-page='outboards'
            // The selector (class or id) of the text element relative to which the header background will fill in
            data-header-fill='.fm-outboard-hero h1'
            // The selector (class or id) of the section/div element relative to which the sticky bottom bar will animate in
            data-sticky-footer='.fm-outboard-hero'
        >

            <PageTitle
                title='Test Page'
                desc='Test Page'
            />

            {/* Hero section */}
            <section
                className='fm-outboard-section fm-outboard-section--hero fm-scrollable-section fm-full-height--sticky-footer'
                data-scroll-label='Overview'
                id='overview'
            >
                <BgVideoContainer
                    maxHeightOverride={'100vh'}
                    options={{
                        ...videoJsOptions,
                        sources: [{
                            // src: packageConfig.videoSource || '',
                            src: '/outboards/hero_rendering_video.mp4',
                            type: 'video/mp4'
                        }]
                    }}
                    // onReady={handlePlayerReady}
                />
            </section>


            {/* Technology Section */}
            <div
                className='fm-scrollable-section fm-full-height--sticky-footer-lg'
                data-scroll-label={'Technology'}
                id='technology'
            >
                {/* Technology: Scroll Panel */}
                <section
                    className={`fm-outboard-section fm-full-height--sticky-footer p-0`}
                    id='tech-scroll-panel'
                >
                    {/* <ScrollPanel
                        {...page.tech.scrollerPanel}
                    /> */}

                    <div className='test-parallax d-flex'>

                        <div
                            className='test-parallax__img-container'
                            style={{backgroundImage: `url("${page.tech.scrollerPanel.image}")`}}
                        >
                        
                        </div>

                        <div /* className='fm-scroll-panel__txt-wrap align-self-lg-center' */>

                            <div className='fm-scroll-panel__txt-inner text-center text-lg-start h-100 d-flex flex-column justify-content-between'>
                                <div>
                                    <div className='fm-scroll-panel__txt-body'>
                                        <p>Lorem ipsum dolor sit amet.</p>
                                        <p>Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet Lorem ipsum dolor sit ametLorem ipsum dolor sit amet.</p>
                                        <p>Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet Lorem ipsum dolor...</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </section>
            
            </div>
            {/* END Technology Section */}


            {/* Charging Section */}
            <div
                className={'fm-outboard-section fm-scrollable-section'}
                data-scroll-label={page.charging.label}
                id={page.charging.id}
            >
                {/* Charging: Full Size Image */}
                <section
                    className={`fm-full-height--sticky-footer-lg p-0`}
                    id='chargingImage'
                >
                   <BgImgContainer
                        img={page.charging.imagePanel}
                   />
                </section>

            </div>
            {/* END Charging Section */}


            <StickyButtons>

                <Button
                    label={'FAQs'}
                    theme={'secondary'}
                    url={'/faqs'}
                />

                <Button
                    label={'Configure'}
                    stateObj={{
                        modelId: 4
                    }}
                    theme={'accent'}
                    url={'/configure'}
                />

            </StickyButtons>

        </div>
  	)

}