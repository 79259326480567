/* import { useRef, useEffect, useState } from 'react'; */

import { Link } from 'react-router-dom';

/*
    Spec Component Props:

        label (string): Text string to display beneath value & unit in smaller text
        unit (string): Text string to display alongside value in large text w/ accent color (optional)
        value (string): Alphanumeric string value to be display in large text w/ accent color
*/

const Spec = (props) => {
    return (

        <div className='fm-spec text-center'>

            <div className='fm-spec__value'>
                {props.value}
                {props.unit && (
                    <>&nbsp;{props.unit}</>
                )}
            </div>

            <p className='fm-spec__label'>
                {props.label}
            </p>

        </div>

    );
};



export default function SpecsPanel({ disclaimer, title, specs = [] }) {

	return (
        <div className='fm-specs-panel fm-max-out'>

            {title && (
                <p className='fm-specs-panel__title text-center text-md-start'>
                    {title}
                </p>
            )}

            <div className='fm-specs-panel__specs d-flex flex-column align-items-center flex-md-row justify-content-md-between'>

                {specs.map((spec, i) =>
                    <Spec
                        key={`${spec.value}-${i}`}
                        label={spec.label}
                        unit={spec.unit}
                        value={spec.value}
                    />
                )}

            </div>

            {disclaimer && (
                <p className='disclaimer text-center text-md-start'>
                    &#42;&nbsp;{disclaimer}
                </p>
            )}

        </div>
	);
}
