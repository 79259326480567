const mosaicConfig = [

    {
        // Alternate text describing image contents (for use with a screenreader)
        altText: 'A Rivian truck tows an electric highfield inflatable boat to launch it to the water.',

        // Hubspot Image URL
        src: '/homepage/mosaic/Home-Tile-1.jpg',
    },

    {
        // Alternate text describing image contents (for use with a screenreader)
        altText: 'Close up of an electric flux marine outboard motor from the side.',

        // Hubspot Image URL
        src: '/homepage/mosaic/Home-Tile-2.jpg',
    },

    {
        // Alternate text describing image contents (for use with a screenreader)
        altText: 'Close up of the Flux logo on an electric outboard.',

        // Hubspot Image URL
        src: '/homepage/mosaic/Home-Tile-3.jpg',
    },

    {
        // Alternate text describing image contents (for use with a screenreader)
        altText: 'A wake-boarder straps in from on an electric boat before entering the water.',

        // Hubspot Image URL
        src: '/homepage/mosaic/Home-Tile-4.jpg',
    },

    {
        // Alternate text describing image contents (for use with a screenreader)
        altText: 'An electric dual console boat moves from right to left from afar.',

        // Hubspot Image URL
        src: '/homepage/mosaic/home_tile_5.jpg',
    },

    {
        // Alternate text describing image contents (for use with a screenreader)
        altText: 'A mother and daughter enjoy the sun on an electric dual console boat.',

        // Hubspot Image URL
        src: '/homepage/mosaic/Home-Tile-6.jpg',
    },

    {
        // Alternate text describing image contents (for use with a screenreader)
        altText: 'An electric outboard motor from afar with the glaring sun focused on it.',

        // Hubspot Image URL
        src: '/homepage/mosaic/home_tile_7.jpg',
    },

    {
        // Alternate text describing image contents (for use with a screenreader)
        altText: 'Close up of an electric dual console boat moves at speed from behind.',

        // Hubspot Image URL
        src: '/homepage/mosaic/home_tile_8.jpg',
    },

    {
        // Alternate text describing image contents (for use with a screenreader)
        altText: 'An electric dual console and electric highfield rigid inflatable move slowly on the water.',

        // Hubspot Image URL
        src: '/homepage/mosaic/home_tile_9.jpg',
    },

    {
        // Alternate text describing image contents (for use with a screenreader)
        altText: 'An electric outboard motor wake as seen from behind.',

        // Hubspot Image URL
        src: '/homepage/mosaic/Home-Tile-10.jpg',
    },

    {
        // Alternate text describing image contents (for use with a screenreader)
        altText: 'Flux Marine mobile app held in hand with an electric outboard in the background.',

        // Hubspot Image URL
        src: '/homepage/mosaic/Home-Tile-11.jpg',
    }

];


export default mosaicConfig;