import Modal from '../global/Modal';

export default function OrderSuccessModal({ closeFunc, show = false }) {

	const closeAndClear = () => {
		closeFunc();
	};

	return (

        <Modal
            closeModal={closeAndClear}
            footerContent={
                <div className='d-flex justify-content-center'>
                    <button className={'fm-btn'} onClick={closeAndClear}>
                        <span>Done</span>
                    </button>
                </div>
            }
            id='orderConfirmationModal'
            title={'Thank you for your order!'}
            show={show}
        >
            <div className='d-flex justify-content-center'>
                You will receive an email confirmation.
            </div>
        </Modal>

	)
}
