const futureConfig = {

    id: 'future-offerings',

    label: 'Future Offerings',

    sectionTitle: 'Coming in 2025',

    reserveLabel: 'Reserve your System',

    futureModels: [
        
        {
            label: {
                img: '/outboards/coming-soon/FM40-Text.png',
                text: 'FM40'
            },

            contPower: 40,

            accPower: 70,

            colors: [
                {
                    color: null,
                    default: true,
                    img: '/outboards/coming-soon/FM70_100_Coming_Soon.png'
                }
            ],

            cta: {
                customClass: 'fm-btn--reserve',
                label: 'Reserve',
                stateObj: {
                    modelId: 4
                },
                theme: 'secondary-accent'
            }

        },

        {
            label: {
                img: '/outboards/coming-soon/FM15-Text.png',
                text: 'FM15'
            },

            contPower: 15,

            accPower: 25,

            colors: [
                {
                    color: null,
                    default: true,
                    img: '/outboards/coming-soon/FM70_100_Coming_Soon.png'
                }
            ],

            cta: {
                customClass: 'fm-btn--reserve',
                label: 'Reserve',
                stateObj: {
                    modelId: 4
                },
                theme: 'secondary-accent'
            }

        }

    ],

    repower: {

        title: 'Repower Your Boat with a Flux Outboard',

        label: 'Coming in 2025',

        // Alternate text describing image contents (for use with a screenreader)
        altText: 'A diagram of a Flux Marine Motor and Hull integration.',

        // Desktop Image Source
        desktopImg: '/outboards/coming-soon/repower_graphic.svg',

        // Mobile Image Source
        mobileImg: '/outboards/coming-soon/repower_graphic_mobile.svg'
        
    }

};

export default futureConfig;