import { useContext, useEffect, useRef, useState } from 'react';

import { GlobalContext } from '../../contexts/GlobalContext';

// Utilities
import useOnScreen from '../../lib/element-on-screen';


export default function OutboardDesign({ img, contentBlocks }) {

    const { cdnUrl } = useContext(GlobalContext);

    const componentRef = useRef();

    const [start, setStart] = useState(false);
    const [end, setEnd] = useState(true);


    const handlePageScroll = () => {

        // Reference the height of the window, header and sticky bottom bar
        const windowHeight = window.innerHeight,
            headerHeight = document.querySelector('.fm-header').clientHeight,
            stickyBarHeight = document.querySelector('.fm-sticky-buttons').clientHeight;
        
        // Reference the distance of the top/bottom of the component instance from the top of the window and its height
        const sectionTop = componentRef.current.offsetTop,
            componentHeight = componentRef.current.clientHeight,
            sectionEnd = sectionTop + componentHeight;

        if (
            !start 
            && (window.scrollY + headerHeight) >= sectionTop
            && ( (sectionEnd - window.scrollY) >= (windowHeight - stickyBarHeight) ) 
        ) {
            setStart(true);
        }
        
        else {
            setStart(false);
        }

        if ( 
            ( (window.scrollY + headerHeight) < sectionEnd )
            && ( (sectionEnd - window.scrollY) < (windowHeight - stickyBarHeight) )
        ) {
            setEnd(true);
        } else {
            setEnd(false);
        }
    };

    useEffect(() => {

        // Add page scroll listener & handler
        document.addEventListener('scroll', handlePageScroll);

        // Remove up scroll listener on component unmount
        return () => document.removeEventListener('scroll', handlePageScroll);

    }, []);


    return (
      
        <div
            className={`fm-outboard-design${start ? ' fm-outboard-design--show' : ''}${end ? ' fm-outboard-design--end' : ''} d-flex flex-column flex-lg-row`}
            ref={componentRef}
        >
            <div
                className='fm-outboard-design__img-wrap'
                style={{backgroundImage: `url(${cdnUrl}${img})`}}
            >

            </div>

            <div className='fm-outboard-design__txt-wrap'>

                { contentBlocks.map((block, i) => 
                        
                    <div
                        className={`
                            fm-outboard-design__txt-block${i === 0 ? ' fm-outboard-design__headings' : ''}
                            fm-full-height--sticky-footer-lg
                        `}
                        data-block={i+1}
                        key={`block-${i+1}`}
                    >
                        {block}
                    </div>

                )}

            </div>

        </div>

    );
}