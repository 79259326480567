import { Link } from 'react-router-dom';

// FAQ page meta description
const metaDesc = 'Find out everything you need to know about electric boating and Flux Marine\'s 100% electric outboards.';

const FAQLink = ({ linkText = 'Link Text', stateObj, to = '#' }) => (
    <Link
        className='fm-faq__link'
        state={stateObj}
        to={to}
    >
        {linkText}
    </Link>
) ;


// FAQ Configuration Object
const questions = {

    'Products': [
        {
            question: 'Is electricity on boats safe?',
            answer: 'Electricity on boats is not a new challenge, as most boats have auxiliary power systems to provide energy for functions other than propulsion. All electronics, including the outboard and battery pack, are waterproofed to IP67 standards. Our system uses an array of sensors to actively monitor functionality and detect failure points. In the event of a fault, the system will de-energize. In other words, we have taken every precaution to keep you safe on the water.'
        },
        {
            question: 'Where do the batteries go?',
            answer: 'The batteries are typically placed in the boat hull, often where a gas tank would have otherwise been. While the battery packs are generally heavier than gas, they are also denser, so they fit in a similar space.'
        },
        {
            question: 'What is the equivalent horsepower rating to a combustion engine?',
            answer: 'We rate horsepower based on the continuous power each motor can produce at the propeller. That means the FM100 really does produce 100HP continuously but has the acceleration power of 150HP for short bursts.'
        },
        {
            question: 'Where can I charge?',
            answer: 'Most marinas have shore power capable of charging a Flux system. For customers that trailer their boat, the batteries can be charged from any outlet or standard EV charger.'
        },
        {
            question: 'What type of batteries do you use?',
            answer: 'We use lithium-ion batteries.'
        },
        {
            question: 'How far can I go on a single charge?',
            answer: (<>
                Range can vary across our boat packages based on power consumption, beginning state of charge, weather conditions, and the size of the battery pack. Our battery configuration for each boat package meets 95% of use cases. Check out our <FAQLink linkText={'outboard'} stateObj={{scrollTo: 'specs'}}  to='/outboards' /> and <FAQLink linkText={'boat package'} stateObj={{scrollTo: 'boat-options'}}  to='/packages/rigid-inflatables' /> specs for further range estimates.
            </>)
        },
        {
            question: 'How long will it take to charge?',
            answer: 'Depending on the power supply available, the system can be charged up to 80% in as little as an hour. Common power pedestals will produce a full charge in 6-12 hours.'
        },
        {
            question: 'Can I convert my current ICE boat to a Flux Marine outboard?',
            answer: 'Flux outboards adhere to American Boat and Yacht Council (ABYC) standards. If your boat is set up with outboard power, it could be a candidate for a Flux repower and you should consult a member of our team!'
        },
        {
            question: 'What are the benefits of an electric outboard?',
            answer: 'To name a few: no fumes, less noise, no winterization, and exhilarating acceleration.'
        },
        {
            question: 'What is included with the outboard system?',
            answer: '100% electric outboard, battery pack, throttle, on-board charger, mobile charging cable.'
        }
    ],

    'General': [
        {
            question: 'What is the warranty of a Flux Marine Outboard and boat package?',
            answer: (<>
                We warrant the following parts of your Flux Marine Package to be free from defects in materials and workmanship in the following periods described hereafter:
                <ul style={{marginTop: "10px"}}>
                    <li>5 years standard warranty on drivetrain</li>
                    <li>5 years warranty on batteries.</li>
                    <li>Non-manufactured Flux Marine items including but not limited to boats, electrical equipment, hardware, and other items will receive the manufacturer's warranties based on the specific manufacturer policies.</li>
                </ul>
            </>)
        },
        {
            question: 'How long is the estimated delivery time?',
            answer: 'Flux Marine has already started delivering systems to the public. Placing a preorder today will reserve your spot in line for our next production batch. We\'ll contact you roughly one month before your system is built to schedule final delivery.'
        }
    ],

    'Servicing': [
        {
            question: 'Who can service my boat and motor?',
            answer: 'Our motors are IOT connected and can be monitored and updated remotely for basic repairs and performance improvements. Local mechanics can be enlisted to service the rest of the boat and any other servicing.'
        },
        {
            question: 'What do I do if the battery runs out while I am out at sea? Is there solar back up, a roll-on/roll off battery option or a gas/diesel back up generator?',
            answer: 'Our outboards are equipped with a "Limp-Home Mode," which activates when you dip below 10% charge. This limits the power via software override, which will drastically extend your range. In "Limp-Home Mode" you can travel the full distance you traveled with the initial 90% of your battery with that last 10% because the motor is hyper-efficient at slow speeds.'
        },
        {
            question: 'Can I purchase a Flux Marine motor through a dealer?',
            answer: (<>Currently pre-orders can only be placed through Flux Marine's website or via a Flux Marine sales representative who you can <FAQLink linkText={'contact here'}  to='/about' stateObj={{ scrollTo: 1 }}/>.</>)
        }
    ]

};
  
export { metaDesc, questions};