const batteryConfig = {

    specsPanel: {

        label: 'Charge from 20% to 80% in as little as...',

        specs: [
            {
                label: '110v, 30amp',
                unit: '',
                value: 'Overnight'
            },
            {
                label: '220v, 30amp',
                unit: 'hr',
                value: '5'
            },
            {
                label: 'Level 3 Fast Charge',
                unit: 'hr',
                value: '1.5'
            }
        ]

    },

    imagePanel: {

        altText: 'Electric Dual Console boat on calm waters from behind.',

        image: '/packages/dual-console/dual_console_battery.jpg'

    },

    platformsPanel: {

        altText: 'Rendering of a Flux Marine Dual Console bowrider electric boat seen from above.',

        disclaimer: [
            'Metrics subject to change in production system',
            'Range extends an additional 10 miles at harbor speeds. Top and Cruising Speeds dependent on passenger count, sea state, and trim.',
        ],

        image: '/packages/dual-console/top_rendering.png',
        
        platforms: [
            {
                name: 'Standard Range',
                desc: 'Standard battery platform meets the needs for most use cases. The ride is quiet, smooth, and exhilarating.',
                startingPrice: '100,000', //$
                specs: [
                    {
                        label: 'Acceleration Power',
                        vals: [
                            {
                                val: 150,
                                unit: 'HP'
                            },
                            {
                                val: 111,
                                unit: 'kW'
                            }
                        ]
                    },
                    {
                        label: 'Est. Cruising Range',
                        vals: [
                            {
                                val: '25-30',
                                unit: 'mi'
                            },
                            {
                                val: '40-48',
                                unit: 'km'
                            }
                        ]
                    },
                    {
                        label: 'Cruising Speed',
                        vals: [
                            {
                                val: 24,
                                unit: 'mph'
                            },
                            {
                                val: 21,
                                unit: 'kt'
                            }
                        ]
                    },
                    {
                        label: 'Top Speed',
                        vals: [
                            {
                                val: 30,
                                unit: 'mph'
                            },
                            {
                                val: 26,
                                unit: 'kt'
                            }
                        ]
                    },
                    {
                        label: 'MSRP',
                        vals: [
                            {
                                val: '$120,000.00',
                                unit: ''
                            }
                        ]
                    }
                ]
            }/*,
            {
                name: 'Extended Range',
                desc: (<>Extended battery platform delivers immense power and acceleration with longer range.<br className='d-none d-lg-inline' /><br className='d-none d-md-inline' /></>),
                startingPrice: '130,000', //$
                specs: [
                    {
                        label: 'Acceleration Power',
                        vals: [
                            {
                                val: 150,
                                unit: 'hp'
                            },
                            {
                                val: 111,
                                unit: 'kW'
                            }
                        ]
                    },
                    {
                        label: 'Est. Cruising Range',
                        vals: [
                            {
                                val: 35,
                                unit: 'mi'
                            },
                            {
                                val: 56,
                                unit: 'km'
                            }
                        ]
                    },
                    {
                        label: 'Cruising Speed',
                        vals: [
                            {
                                val: 24,
                                unit: 'mph'
                            },
                            {
                                val: 21,
                                unit: 'kt'
                            }
                        ]
                    },
                    {
                        label: 'Top Speed',
                        vals: [
                            {
                                val: 30,
                                unit: 'mph'
                            },
                            {
                                val: 26,
                                unit: 'kt'
                            }
                        ]
                    },
                    {
                        label: 'MSRP',
                        vals: [
                            {
                                val: '$150,000.00',
                                unit: ''
                            }
                        ]
                    }
                ]
            }*/
        ]
    }

};

export default batteryConfig;