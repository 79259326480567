const moreInfoConfig = {

    choices: [
        {
            id: 1,
            name: 'Repower'
        },
        {
            id: 2,
            name: 'Other Boat Package'
        }
    ],

    fieldName: 'moreInfo',

    name: 'More Info'
};

export default moreInfoConfig;