// Configuration Object
const contentPanelsConfig = [

    {
        // Lowercase version of label below (multi-word labels need an id with hyphens in place of spaces)
        id: 'charging',

        // Appears along the sticky bottom bar
        label: 'Charging',

        image: '/outboards/chargers.svg',
        altText: 'Flux Marine charging port.',
        title: 'Charge From Anywhere',
        body: (<>
            Take advantage of the existing power infrastructure at your home or local marina. Compatible with 110V, 220V, 240V, and the growing network of DC Fast chargers&#42;.
            Every outboard comes with a mobile charging cable and an easily accessible charge port.
        </>)
    },

    /* {
        // Lowercase version of label below (multi-word labels need an id with hyphens in place of spaces)
        id: 'software',

        // Appears along the sticky bottom bar
        label: 'Software',

        image: '/outboards/software_new.png',
        altText: 'A Flux Marine user experience on a Garmin Marine dashboard.',
        title: 'Command Your Experience',
        body: (<>
            Take complete control at the helm with live updates on the integrated Garmin chartplotter.
            <br /><br />
            Monitor your speed, battery life, and range with the tap of a finger using the Flux dashboard.
            <br /><br />
            Our throttle is built to pair with the Flux dashboard and mobile app to provide a smoother, smarter ride.
        </>)
    } */
    
];


export default contentPanelsConfig;