const scrollUtil = {

    scrollToSection: (targetRef) => {
        window.scrollTo({
            top: targetRef.current.offsetTop - 64, // Header is 64px in height
            behavior: 'smooth'
        });
    },

};

export default scrollUtil;