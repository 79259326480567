import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/* import scrollUtil from '../../lib/scroll-utility'; */

import BgImgContainer from "../global/BgImgContainer";


export default function PackageHero({ /* disclaimer, */ img, name/* , specs */ }) {

	return (

        <div className='fm-package-hero'>

            <BgImgContainer img={img}>

                <div className='fm-package-hero__inner'>

                    <h1 className='fm-package-hero__header text-center text-md-start'>
                        <div className='fm-package-hero__label'>100% Electric</div>
                        <div className='fm-package-hero__name'>{name}</div>
                    </h1>

                   {/*  <div className='fm-package-hero__specs d-grid d-md-flex flex-md-row justify-content-md-between align-items-md-center'>

                        {specs.map((spec, i) => 
                            <div className='fm-package-hero__spec' key={`${spec.label}-${i}`}>
                                <div className='fm-fw-medium'>
                                    <span className='fm-package-hero__spec-val'>{spec.val}</span>
                                    <span className='fm-package-hero__spec-unit'>&nbsp;{spec.unit}</span>
                                </div>
                                <div>
                                    <span className='fm-package-hero__spec-label'>{spec.label}</span>
                                </div>
                            </div>
                        )}

                    </div> */}

                    {/* <p className='fm-package-hero__disclaimer text-center text-md-start'>&#42;&nbsp;The product shown is a prototype and is not available as a production system in the current form. {disclaimer}</p> */}

                </div>

            </BgImgContainer>

        </div>

	)
}

