const chargingConfig = {

    // Lowercase version of label below (multi-word labels need an id with hyphens in place of spaces)
    id: 'charging',

    // Appears along the sticky bottom bar
    label: 'Charging',

    contentPanel: {

        // Image Source
        image: '/outboards/chargers_v3.png',

        // Alternate text describing image contents (for use with a screenreader)
        altText: 'Flux Marine charging port.',

        // Heading
        title: 'Charge From Anywhere',

        // Must remain inside parenthesis and <></>; use <br /> for line breaks
        body: (<>
            Take advantage of the existing power infrastructure at your home or local marina. Compatible with 120V, 240V, and the growing network of DC Fast chargers*. Every outboard comes with a charging cable and an easily accessible charge port.
        </>)
    }

};

export default chargingConfig;