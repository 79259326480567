import { useContext } from 'react';

import { Link } from 'react-router-dom';

import { GlobalContext } from '../../contexts/GlobalContext';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function PackagePlatforms({ altText, disclaimer, image, platforms }) {

    const { cdnUrl } = useContext(GlobalContext);

	return (

        <div className='fm-package-platforms w-100'> {/* fm-max-out removed, replaced with w-100 */}

                <h2 className='fm-package-platforms__title text-center'>
                    Specficiations
                </h2>

                {image && (
                    <>
                        <img
                            alt={altText}
                            className='fm-package-platforms__img img-fluid d-block'
                            src={`${cdnUrl}${image}`}
                        />
                    </>
                )}


                <div className={`fm-package-platforms__text-content${!image ? ' fm-package-platforms__text-content--no-img' : ''} d-flex flex-column flex-md-row`}>
                    
                    {platforms.map((platform, i) =>

                        <div className='fm-package-platforms__pf-wrapper' key={`${platform.name}-${i}`}>
                            
                            <div className={`fm-package-platforms__pf ${i === 1 ? 'fm-package-platforms__pf--second' : ''}`}>

                                <div className='fm-package-platforms__pf-inner'>

                                    <div className='fm-package-platforms__pf-name'>
                                        <span>{platform.name}</span>
                                    </div>

                                    <p className='fm-package-platforms__pf-desc'>{platform.desc}</p>

                                    {/* <p className='fm-package-platforms__pf-price text-center'>Starting at ${platform.startingPrice} USD</p> */}

                                    {platform.specs && platform.specs.length > 0 && (
                                        <div className='fm-package-platforms__pf-specs'>

                                            {platform.specs.map((spec, j) =>
                                                <div className='d-flex flex-row justify-content-between' key={`${spec.label}-${j}`}>
                                                    <span>{spec.label}</span>
                                                    <div>
                                                        <span>{spec.vals[0].val} {spec.vals[0].unit}</span>
                                                        {spec.vals.length > 1 && (
                                                            <>
                                                                <span>&nbsp;/&nbsp;</span>
                                                                <span>{spec.vals[1].val} {spec.vals[1].unit}</span>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    )}

                                </div>
                                
                            </div>
                        </div>
                    )}

                </div>

                {disclaimer && disclaimer.map((text, i) => 
                    <p
                        className='disclaimer text-center text-md-start'
                        key={text}
                    >
                        &#42;&nbsp;{text}
                    </p>
                )}
                
        </div>

	);
}

