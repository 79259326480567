// Configuration Object
const navMenuConfig = [
    {
        label: 'Outboards',
        url: '/outboards',
        pageLinks: [
            /* {
                label: 'FM15',
                url: `/outboards?scrollTo=fm15`
            },
            {
                label: 'FM40',
                url: `/outboards?scrollTo=fm40`
            },
            {
                label: 'FM70',
                url: `/outboards?scrollTo=fm70`
            }, */
        ]
    },
    {
        expanded: true,
        label: 'Boat Packages',
        url: '',
        pageLinks: [
            {
                label: 'Rigid Inflatables',
                url: `/packages/rigid-inflatables`
            },
            /* {
                label: 'Yacht Tender',
                url: `/packages/yacht-tender`
            }, */
            {
                label: 'Dual Console',
                url: `/packages/dual-console`
            },
            {
                label: 'Center Console',
                url: `/packages/center-console`
            }
            // Hiding Pontoon page for launch
            /* ,
            {
                label: 'Pontoon',
                url: `/packages/pontoon`
            } */ 
        ]
    },
    {
        expanded: true,
        label: 'Our Company',
        url: '',
        pageLinks: [
            {
                label: 'About Us',
                url: '/about'
            },
            /* {
                label: 'Mission',
                url: '/mission'
            }, */
            {
                label: 'Contact Us',
                url: '/about',
                state: {scrollTo: 1}
            },
            {
                label: 'Careers',
                target: '_blank',
                url: 'https://fluxmarine.teamtailor.com/'
            },
            {
                label: 'Blog',
                url: '/blog'

            },
            {
                label: 'Privacy & Legal',
                url: '/privacy'
            }
        ]
    },
    {
        expanded: true,
        label: 'Forms',
        url: '',
        pageLinks: [
            {
                label: 'Schedule a Sea Trial',
                url: '/about',
                state: {scrollTo: 1, inquiry: 'Request a Sea Trial'}
            },
            {
                label: 'Dealer Interest',
                url: '/about',
                state: {scrollTo: 1, inquiry: 'Dealers / OEM Partners'}
            },
            {
                label: 'Become a Partner',
                url: '/about',
                state: {scrollTo: 1, inquiry: 'Dealers / OEM Partners'}
            },
        ]
    },
    /* {
        expanded: false,
        label: 'Media',
        url: '', // Need to disable this
        pageLinks: [
            {
                label: 'News & Updates',
                url: '#'
            },
            {
                label: 'Content',
                url: '#'
            },
            {
                label: 'Press Kit',
                url: '#'
            }
        ]
    }, */
    {
        label: (<>FAQ<span className='text-lowercase'>s</span></>),
        url: '/faqs',
        pageLinks: []
    },
];

  
export default navMenuConfig;