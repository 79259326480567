// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import { faLinkedin, faInstagram, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';
import {
    faPlus,
    faMinus,
    faArrowRight,
    faArrowRightLong,
    faArrowLeftLong,
    faXmark,
    faBars,
    faChevronDown,
    faChevronUp,
    faChevronLeft,
    faChevronRight,
    faPencil,
    faSailboat
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faLinkedin,
    faInstagram,
    faFacebook,
    faYoutube,
    faPlus,
    faMinus,
    faArrowRight,
    faArrowRightLong,
    faArrowLeftLong,
    faXmark,
    faBars,
    faChevronDown,
    faChevronUp,
    faChevronLeft,
    faChevronRight,
    faPencil,
    faSailboat
);