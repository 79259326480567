const outboardConfig = {

    // Image Source
    image: '/packages/dual-console/DC-Outboard.jpg',

    // Alternate text describing image contents (for use with a screenreader)
    altText: 'An electric Dual Console boat moves from right to left on the water.',

    // Heading
    title: '100% Electric Outboard Motor',

    // Must remain inside parenthesis and <></>; use <br /> for line breaks
    body: (<>
        <p>
            Purchase a curated boat with our outboard, battery pack, throttle, display, and charger already built in. Enjoy instant torque with gas-equivalent horsepower ratings without the emissions or clunky noise.
        </p>
        <p>
            Take advantage of the existing power infrastructure at your home or local marina. Compatible with 110V, 220V, 240V, and the growing network of DC Fast chargers.
        </p>
        <p>
            Enjoy a full day of boating from one night of charging.<br />Never visit a fuel dock again.
        </p>
    </>),

    // Button Text
    ctaText: 'More Outboard Info',

    // Button Relative URL
    ctaUrl: '/outboards'

};

export default outboardConfig;