

import { ReactComponent as CheckmarkSVG } from '../../assets/icons/icon_check.svg';

export default function MobileSteps({ activeStep = -1, changeStep, steps = [] }) {

    const getStepClasses = (step) => {
        const base = 'fm-studio-stepper__step';
        let classes = base.slice();

        if (activeStep === step) {
            classes += ` ${base}--active`;
        }
        else if (activeStep > step) {
            classes += ` ${base}--complete`;
        }
        else if (steps[step - 1].validated || steps[step - 2].validated) {
            classes += ` ${base}--enabled`;
        }
        else {
            classes += ` ${base}--disabled`;
        }
        return classes;
    };

    return (
        <div className='fm-studio-stepper d-lg-none'>

            <div className='fm-studio-stepper__inner d-flex flex-row'>

                { steps.map((step, index) =>

                    <div
                        className={getStepClasses(index+1)}
                        data-valid={step.validated}
                        key={index}
                    >

                        <button
                            className='fm-studio-stepper__button'
                            onClick={() => changeStep(index+1)}
                            tabIndex={
                                ( 
                                    activeStep == index+1 
                                    || ( (activeStep < index+1) && !steps[index-1].validated)
                                ) ? -1 : null
                            }
                        >
                            {index + 1 >= activeStep ? index + 1 : (<CheckmarkSVG />)}
                        </button>

                        <div className='fm-studio-stepper__label'>
                            {step.name}
                        </div>

                        { index < steps.length - 1 && (
                            <div className='fm-studio-stepper__line'></div> 
                        )}

                    </div>

                )}

            </div>

        </div>
    );

}