/* import { useRef, useEffect, useState } from 'react'; */

import { Link } from 'react-router-dom';


export default function Loader({ size }) {

	return (
        <div className={`fm-loader${size ? ` fm-loader--${size}` : ''}`}>
            <div className='fm-loader__bounce fm-loader__bounce--1'></div>
            <div className='fm-loader__bounce fm-loader__bounce--2'></div>
        </div>
	);

}
