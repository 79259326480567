import { useContext } from 'react';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { GlobalContext } from '../../contexts/GlobalContext';

import Button from '../global/Button';


export default function AboutUsHero({ config, children }) {

    const { cdnUrl } = useContext(GlobalContext);

	return (
        <>

            <div className='fm-about-hero'>

                <div className='fm-max-out'>
                    <h1 className='fm-about-hero__title'>
                        {config.largeText}
                    </h1>
                </div>

                <img
                    alt={config.imageDescription}
                    className='fm-about-hero__img img-fluid w-100'
                    src={`${cdnUrl}${config.image}`}
                />

                <div className='fm-about-hero__body fm-max-out'>

                    {config.textBlocks.map((text, i) =>

                        <p
                            className='fm-about-hero__text'
                            key={i}
                        >
                            {text}
                        </p>

                    )}
                    
                </div>

            </div>

        </>

	)
}

