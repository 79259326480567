// Import modular config files
import heroCarouselConfig from './hero-carousel-config';
import contentPanelsConfig from './content-sections-config';
/* import imgCarouselConfig from './image-carousel-config'; */
import missionBannerConfig from './mission-config';
import productLineupConfig from './product-lineup-config';
import mosaicConfig from './mosaic-config';

// Configuration Object
const homepageConfig = {

    metaDesc: 'Revolutionizing Marine Electrification. 100% Electric Outboards.',

    hero: heroCarouselConfig,

    productLineup: productLineupConfig,

    missionBanner: missionBannerConfig,

    contentPanels: contentPanelsConfig,

    /* images: imgCarouselConfig, */

    mosaic: mosaicConfig

};
  
export default homepageConfig;