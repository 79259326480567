import dashboardConfig from './dashboard-config';
import hullConfig from './hull-config';
import outboardConfig from './outboard-config';
import boatOptionsConfig from './boat-options-config';
/* import mosaicConfig from './mosaic-config'; */


const rigidInflatableConfig = {

    name: 'Rigid Inflatables',

    metaDesc: 'Pre-order your 100% electric Highfield rigid inflatable boat package. Available in three sizes to suit your boating needs.',

    image: '/packages/inflatable/rendering.png',

    altText: 'Rendering of a Flux Marine Inflatable electric Boat.',

    hero: {

        img: {

            altText: 'An Electric Highfield Rigid Inflatable Boat moving from right to left.',

            image: '/packages/inflatable/RIB-Top-Image.jpg'
            
        },

        /* specs: [
            {
                label: 'Continuous Power',
                val: '40 - 100',
                unit: 'HP'
            },
            {
                label: 'Estimated Cruising Range',
                val: '26 - 40',
                unit: 'mi'
            },
            {
                label: 'Top Speed',
                val: '32 - 35',
                unit: 'mph'
            },
            {
                label: 'Starting Price',
                val: '$35,000',
                unit: ''
            }
        ] */
    },

    overviewSpecs: [
        {
            label: 'Continuous Power',
            value: '100',
            unit: 'HP'
        },
        {
            label: 'Est. Cruising Range',
            value: '26-30',
            unit: 'mi'
        },
        {
            label: 'Top Speed',
            value: '32-35',
            unit: 'mph'
        }/* ,
        {
            label: 'Starting Price',
            value: '$35,000',
            unit: ''
        } */
    ],

    videoDesc: 'A video compilation of Flux Marine Electric Rigid Inflatable at speed and rest.',

    videoSrc: '/packages/inflatable/RIB-Video.mp4',

    startingPrice: '$35,000',

    platforms: [
        {
            name: 'Standard',
            desc: 'Standard battery platform meets the needs for most use cases. The ride is quiet, smooth, and exhilarating. Optional tow package available.',
            startingPrice: '100,000', //$
            specs: [
                {
                    label: 'Peak Power',
                    vals: [
                        {
                            val: 60,
                            unit: 'hp'
                        },
                        {
                            val: 28,
                            unit: 'kW'
                        }
                    ]
                },
                {
                    label: 'Est. Range',
                    vals: [
                        {
                            val: 30,
                            unit: 'mi'
                        },
                        {
                            val: 28,
                            unit: 'km'
                        }
                    ]
                },
                {
                    label: 'Top Speed',
                    vals: [
                        {
                            val: 25,
                            unit: 'mph'
                        },
                        {
                            val: 22,
                            unit: 'kt'
                        }
                    ]
                }
            ]
        },
        {
            name: 'Long Range',
            desc: 'Extended battery platform allows for the longest range and delivers insane power and acceleration. Optional tow package available.',
            startingPrice: '130,000', //$
            specs: [
                {
                    label: 'Peak Power',
                    vals: [
                        {
                            val: 80,
                            unit: 'hp'
                        },
                        {
                            val: 50,
                            unit: 'kW'
                        }
                    ]
                },
                {
                    label: 'Est. Range',
                    vals: [
                        {
                            val: 45,
                            unit: 'mi'
                        },
                        {
                            val: 41,
                            unit: 'km'
                        }
                    ]
                },
                {
                    label: 'Top Speed',
                    vals: [
                        {
                            val: 33,
                            unit: 'mph'
                        },
                        {
                            val: 27,
                            unit: 'kt'
                        }
                    ]
                }
            ]
        }
    ],

    dashboard: dashboardConfig,

    hull: hullConfig,

    outboard: outboardConfig,

    battery: boatOptionsConfig,

    /* mosaic: mosaicConfig, */

    disclaimer: 'Metrics subject to change in production system.'

};

export default rigidInflatableConfig;