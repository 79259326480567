
import { useContext, useRef, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { GlobalContext } from '../../contexts/GlobalContext';

import SectionHeader from './SectionHeader';
import SubscriptionModal from '../footer-components/SubscriptionModal';

import socialLinks from '../../config/social-links';


export default function Footer() {

	const { cdnUrl } = useContext(GlobalContext);

	// Footer Reference
    const footerRef = useRef(null);

	// Flag to track if extra bottom padding is needed to account for a sticky footer bar on page
	const [ extraSpace, setExtraSpace ] = useState(false);

	// Flag to store shown/hidden state of Subscription Modal
	const [ showSubscriptionModal, setShowSubscriptionModal ] = useState(false);

	// Show subscription modal on click of 'Subscribe' button
	const handleSubscribeBtnClick = () => {
		setShowSubscriptionModal(true);
	}

	// Hide subscription modal
	const closeSubscriptionModal = () => {
		setShowSubscriptionModal(false);
	};

	useEffect(()=> {
		const stickyButtons = document.querySelector('.fm-sticky-buttons');
		if (stickyButtons !== null) {
			setExtraSpace(true);
		}
	}, []);

	const pagePath = window.location.pathname;


	return (

		<footer className={`fm-footer${extraSpace ? ' fm-footer--extra-space' : ''}`} ref={footerRef}>

			<div className='fm-footer__inner fm-max-out'>

				<div className='fm-footer__top d-flex flex-column flex-xl-row'>
					
					<div className='fm-footer__stay-updated'>
						<SectionHeader title='Stay Updated' />

						{/* <button className='fm-footer__subscribe fm-btn' onClick={handleSubscribeBtnClick}>
							<span>Subscribe</span>
						</button> */}

						<Link className='fm-footer__subscribe fm-btn' to={'/about'} state={{scrollTo: 1, inquiry: 'Subscribe to Newsletter'}}>
							Subscribe
						</Link>

						<p className='fm-footer__subscribe-msg'>
							Get updates on events, products, and new developments<br />from our newsletter.
						</p>
					</div>

					<div className='fm-footer__social d-flex flex-row flex-lg-wrap justify-content-xl-evenly'>

						{socialLinks.map((platform) =>
							<a
								className='fm-footer__social-link'
								href={platform.url}
								key={platform.url}
								rel='noopener noreferrer'
								target='_blank'
							>
								<span className='d-none d-md-inline'>{platform.name}</span>
								<FontAwesomeIcon icon={`fa-brands fa-${platform.name.toLowerCase()}`} size='2xl' />
							</a>
						)}
						
					</div>

				</div>

				<div className='fm-footer__bottom d-flex flex-column flex-lg-row align-items-lg-center'>

					<div className='fm-footer__brand'>
						<div>
							<img
								alt='Flux Marine'
								className='fm-footer__brand-img img-fluid'
								src={`${cdnUrl}/brand/flux_logo_dark.png`}
								width={160}
								height={24}
							/>
						</div>
						<p className='fm-footer__copyright'>&copy;&nbsp;{new Date().getFullYear()} Flux Marine</p>
					</div>

					<div className='fm-footer__bottom-links d-flex align-items-start flex-column flex-md-row justify-content-md-between justify-content-lg-evenly'>
						{/* <Link className='fm-footer__bottom-link basic-hover' to={'/sea-trial'}> */}
						<Link className='fm-footer__bottom-link basic-hover' to={'/about'} state={{scrollTo: 1, inquiry: 'Request a Sea Trial'}}>
							Book A Sea Trial
						</Link>
						<Link className='fm-footer__bottom-link basic-hover' to={'/faqs'}>
							FAQs
						</Link>
						<a
                            className='fm-footer__bottom-link basic-hover'
                            href={'https://fluxmarine.teamtailor.com/'}
							rel='noopener noreferrer'
                            target='_blank'
                        >
                            Careers
                        </a>
						<Link className='fm-footer__bottom-link basic-hover' to={'/blog'}>
							Blog
						</Link>
						<Link className='fm-footer__bottom-link basic-hover' to={'/privacy'}>
							Privacy &amp; Legal
						</Link>
						<Link className='fm-footer__bottom-link basic-hover' to={'/about'} state={{scrollTo: 1}}>
							Contact
						</Link>
						{ pagePath === '/' ?
							(
								<Link
									className='fm-footer__bottom-link basic-hover'
									onClick={ (e) => { e.preventDefault(); window.scrollTo(0,0); } }
									to='#'
								>
									Home
								</Link>
							)
							:
							(
								<Link className='fm-footer__bottom-link basic-hover' to={'/'}>
									Home
								</Link>
							)
						}
					</div>

				</div>
            
			</div>

			<SubscriptionModal
				closeFunc={closeSubscriptionModal}
				show={showSubscriptionModal}
			/>

		</footer>

	)
}
