// Config files by package
import dualConsoleConfig from './dual-console/dual-console-config';
import rigidInflatableConfig from './rigid-inflatable/rigid-inflatable-config';
import centerConsoleConfig from './center-console/center-console-config';
/* import yachtTenderConfig from './yacht-tender/yacht-tender-config'; */


// Configuration Object
const packages = {

    'dual-console': {
        id: 1,
        ...dualConsoleConfig
    },

    'rigid-inflatables': {
        id: 2,
        ...rigidInflatableConfig
    },

    'center-console': {
        id: 3,
        ...centerConsoleConfig
    }

    /* 'yacht-tender': {
        id: 3,
        ...yachtTenderConfig
    }
 */
};
  
export default packages;