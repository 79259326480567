import { useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function Modal({ bodyContent, children, closeModal, footerContent, id, loader = false, title, show }) {

    const modalRef = useRef(null);

    /* useEffect(() => {
        if (show) {
            modalRef.current.classList.add(styles.visible);
        }
        else {
            modalRef.current.classList.remove(styles.visible);
        }
    }, [show]); */


    return (

        <div className={`fm-modal ${show ? 'fm-modal--show' : ''}`} ref={modalRef}>

            <div className='fm-modal__content' id={id} tabIndex='-1' aria-labelledby={`${id}Label`} aria-hidden='true'>

                <div className='fm-modal__header text-center'> {/* d-flex flex-row justify-content-between align-items-center */}
                    <span className='fm-modal__title' id={`${id}Label`}>{title}</span>
                    
                    {!loader && (
                        <button className='fm-modal__close' aria-label='Close' onClick={closeModal} type='button'>
                            <FontAwesomeIcon icon="fa-solid fa-xmark" />
                        </button>
                    )}
                </div>

                <div className='fm-modal__body'>
                    {children}
                </div>

                {footerContent && (
                    <div className='fm-modal__footer'>
                        {footerContent}
                    </div>
                )}

            </div>

        </div>

    )
};