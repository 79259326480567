import dashboardConfig from './dashboard-config';
import hullConfig from './hull-config';
import outboardConfig from './outboard-config';
import batteryConfig from './battery-config';
/* import mosaicConfig from './mosaic-config'; */

const centerConsoleConfig = {
    
    name: 'Center Console',

    metaDesc: 'Pre-order your 100% electric 22-foot center console boat package. The perfect electric solution for a full day of boating , curated to you.',

    altText: 'Rendering of a Flux Marine Center Console bowrider electric Boat.',

    image: '/packages/center-console/rendering.jpg',

    hero: {

        img: {

            altText: 'Electric Center Console boat on calm waters from the side.',
    
            image: '/packages/center-console/center_console_hero.jpg'
    
        },

        /* specs: [
            {
                label: 'Continuous Power',
                val: 100,
                unit: 'HP'
            },
            {
                label: 'Estimated Cruising Range',
                val: '25 - 35',
                unit: 'mi'
            },
            {
                label: 'Top Speed',
                val: 30,
                unit: 'mph'
            },
            {
                label: 'Starting Price',
                val: '$120,000',
                unit: ''
            }
        ], */

    },

    overviewSpecs: [
        {
            label: 'Continuous Power',
            value: 100,
            unit: 'HP'
        },
        {
            label: 'Est. Cruising Range',
            value: '25-30',
            unit: 'mi'
        },
        {
            label: 'Top Speed',
            value: 30,
            unit: 'mph'
        },
        {
            label: 'Starting Price',
            value: '$120,000',
            unit: ''
        }
    ],

    videoDesc: 'A video compilation of Flux Marine Electric Center Console at speed and rest.',

    videoSrc: '',

    startingPrice: '120,000', // $

    dashboard: dashboardConfig,

    hull: hullConfig,

    outboard: outboardConfig,

    battery: batteryConfig,

    /* mosaic: mosaicConfig, */

    disclaimer: 'Metrics subject to change in production system.'

};

export default centerConsoleConfig;