const modelConfig = {

    choices: [
        {
            altTextName: 'Dual Console bowrider',
            id: 1,
            name: 'Dual Console',
            powerIds: [4], // 100HP
            trailerPrice: (power) => 4500,
        },
        {
            altTextName: 'Rigid Inflatables',
            id: 2,
            name: 'Rigid Inflatables',
            powerIds: [2,3,4], // 40HP, 70HP, 100HP
            trailerPrice: (power) => power === 4 ? 4889 : 3500
        },
        {
            altTextName: 'Center Console bowrider',
            id: 3,
            name: 'Center Console',
            powerIds: [4], // 100HP
            trailerPrice: (power) => 4500,
        },
        {
            altTextName: 'Outboard',
            id: 4,
            name: 'Outboard Only',
            powerIds: [1,2,3,4], // 15HP, 40HP, 70HP, 100HP
            outboardColorIds: [1,2]
        }
    ],

    clearOnChange: ['power', 'hullColor', 'outboardColor', 'trailer', 'moreInfo', 'fastCharging'],

    fieldName: 'model',

    name: 'Model'
};



export default modelConfig;