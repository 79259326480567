export default function Careers() {

	return (

        <div className='fm-careers'>

            <span className='visually-hidden'>The Flux Marine offices in Bristol, RI. Several people work at desks in an office setting.</span>

            <div className='fm-careers__content-overlay h-100 d-flex align-items-center'>

                <div className='fm-careers__txt-wrap fm-max-out text-center'>

                    <h2>
                        Propel The Future With Us
                    </h2>

                    <a
                        className='fm-btn fm-btn--secondary'
                        href={'https://fluxmarine.teamtailor.com/'}
                        rel='noopener noreferrer'
                        target='_blank'
                    >
                        See Available Positions
                    </a>

                </div>

            </div>

        </div>
	);

}
