const trailerConfig = {

    choices: [
        {
            id: 1,
            name: 'Yes'
        },
        {
            id: 2,
            name: 'No'
        }
    ],

    fieldName: 'trailer',

    name: 'Trailer'
};

export default trailerConfig;