import { createRef, useEffect, useMemo, useRef, useState } from 'react';

import { Link, useParams } from 'react-router-dom';

// Video JS
/* import videojs from 'video.js';
import 'video.js/dist/video-js.css'; */

// Flux Marine Components
import PageTitle from '../components/PageTitle';
import PackageHero from '../components/package-components/PackageHero';
import SectionHeader from '../components/global/SectionHeader';
import BgVideoContainer from '../components/global/BgVideoContainer';
import PackagePlatforms from '../components/package-components/PackagePlatforms';
import ContentPanel from '../components/global/ContentPanel';
import SpecsPanel from '../components/package-components/SpecsPanel';
/* import ImageCarousel from '../components/global/ImageCarousel'; */
import BgImgContainer from '../components/global/BgImgContainer';
import Button from '../components/global/Button';
/* import Mosaic from '../components/global/Mosaic'; */
import StickyButtons from '../components/global/StickyButtons';
// import InlineVideo from '../components/InlineVideo';

// Packages Config
import packages from '../config/packages/packages';


const useIsInViewport = (ref) => {

    const [isIntersecting, setIsIntersecting] = useState(false);
  
    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting),
        ),
      [],
    );
  
    useEffect(() => {
      observer.observe(ref.current);
  
      return () => {
        observer.disconnect();
      };
    }, [ref, observer]);
  
    return isIntersecting;
};


const videoJsOptions = {
    // autoplay: true,
    controls: false,
    responsive: true,
    fluid: true
};


export default function StdPackage() {

    // Get package page contents from config based on package name in url
    const params = useParams();
    const packageConfig = packages[params.package];

    // Video Section References
    const videoSectionRef = useRef(null);
    // const videoScrollRef = useRef(null);
    const playerRef = useRef(null);

    /* const handlePlayerReady = (player) => {
        playerRef.current = player;
    }; */

    /* const isInViewport1 = useIsInViewport(videoSectionRef);
    useEffect(() => {
        // console.log(isInViewport1);
        if (isInViewport1) {
            playerRef.current.play();
        }
    }, [isInViewport1]); */


  	return (
		<div
            className='fm-page fm-page--sticky-footer'
            data-package={packageConfig.name.toLowerCase().replace(' ', '-')}
            data-page='package'
            // The selector (class or id) of the text element relative to which the header background will fill in
            // data-header-fill='.fm-package-hero__specs'
            data-header-fill='.fm-package-hero__header'
            // The selector (class or id) of the section/div element relative to which the sticky bottom bar will animate in
            data-sticky-footer='.fm-package-hero'
        >

            <PageTitle
                title={`${packageConfig.name} Boat Package`}
                desc={packageConfig.metaDesc}
            />


            {/* Package Hero */}
            <section
                className='fm-scrollable-section fm-full-height--sticky-footer p-0'
                data-scroll-label='Overview'
                id='overview'
            >
                <PackageHero
                    name={packageConfig.name}
                    {...packageConfig.hero}
                />

                <section
                    className='fm-package-specs-panel bg-white-smoke d-flex align-items-center'
                    id='overviewSpecs'
                >
                    <SpecsPanel
                        specs={packageConfig.overviewSpecs}
                    />
                </section>

            </section>
            {/* END Package Hero */}


            {/* <section
                className='fm-package-section fm-package-section--video fm-scrollable-section fm-full-height--sticky-footer bg-white-smoke'
                data-scroll-label='Experience'
                id='video'
                ref={sectionRefs.current[2]}
            > */}

                {/* <InlineVideo
                    options={videoJsOptions}
                    onReady={handlePlayerReady}
                /> */}

                {/* For scroll detection only (not visible on page) */}
                {/* <div className='d-none' ref={videoScrollRef}></div> */}

            {/* </section> */}


            {/* Video section */}
            {packageConfig.videoSrc && (
                <section
                    className=''/* fm-full-height--sticky-footer-lg */
                    id='overviewVideo'
                    ref={videoSectionRef}
                >

                    <BgVideoContainer
                        options={{
                            ...videoJsOptions,
                            sources: [{
                                // src: packageConfig.videoSource || '',
                                src: packageConfig.videoSrc,
                                type: 'video/mp4'
                            }]
                        }}
                        // onReady={handlePlayerReady}
                    />

                    {/* For scroll detection only (not visible on page) */}
                    {/* <div className='d-none' ref={videoScrollRef}></div> */}

                    {/* {% video_player "embed_player" overrideable=False, type='scriptV4', hide_playlist=True, viral_sharing=False, embed_button=False, autoplay=False, hidden_controls=False, loop=False, muted=False, full_width=False, width='3840', height='2160', player_id='128174359383' %} */}

                </section>
            )}
            {/* END Video section */}


            {/* Hull sections container */}
            <div
                className='fm-scrollable-section'
                data-scroll-label={'Hull'}
                id={'hull'}
            >

                {/* Hull: Content Panel */}
                <section
                    className={`fm-package-section p-0 fm-full-height--sticky-footer`}
                >
                    <ContentPanel
                        {...packageConfig.hull.contentPanel}
                        textPosition={'right'}
                    />
                </section>

                {/* Hull: Full Size Image */}
                <section
                    className={`fm-full-height--sticky-footer-lg p-0`}
                    id='hullImage'
                >
                   <BgImgContainer
                        img={packageConfig.hull.imagePanel}
                   />
                </section>

                {/* Hull: Specs Panel */}
                <section
                    className='fm-package-specs-panel bg-white-smoke d-flex align-items-center'
                    /* id='chargingSpecs' */
                >
                    <SpecsPanel
                        specs={packageConfig.hull.specs}
                    />
                </section>

                {/* Hull: Image Carousel */}
                {/* <section 
                    className='fm-images'
                >
                    <ImageCarousel
                        images={packageConfig.hull.images}
                    />
                </section> */}
            
            </div>
            {/* END Hull sections container */}


            {/* Outboard Section */}
            <section
                className={`fm-scrollable-section fm-full-height--sticky-footer p-0`}
                data-scroll-label={'Outboard'}
                id='outboard'
            >

                <BgImgContainer
                    img={{
                        altText: packageConfig.outboard.altText,
                        image: packageConfig.outboard.image
                    }}
                    mask={true}
                >

                    <div>

                        <SectionHeader
                            title={packageConfig.outboard.title}
                        />

                        {packageConfig.outboard.body}

                        <Button
                            label={packageConfig.outboard.ctaText}
                            theme='secondary-accent'
                            url={packageConfig.outboard.ctaUrl}
                        />

                    </div>

                </BgImgContainer>

            </section>
            {/* END Outboard Section */}


            {/* Dashboard Section */}
            <section
                className={`fm-package-section fm-scrollable-section fm-full-height--sticky-footer p-0`}
                data-scroll-label={'Dashboard'}
                id={'dashboard'}
            >
                <ContentPanel
                    {...packageConfig.dashboard}
                    textPosition='right'
                />
                
            </section>
            {/* END Dashboard Section */}

            {/* Full Size Image */}
            <section
                className={`fm-full-height--sticky-footer-lg p-0`}
                id='separatorImage'
            >
                <BgImgContainer
                    img={packageConfig.battery.imagePanel}
                />
            </section>

            
            {/* Battery Sections container */}
            <div
                className='fm-scrollable-section'
                data-scroll-label='Specifications'
                id='specifications'
            >

                {/* Battery: Charging Specs */}
                <section
                    className={`fm-package-specs-panel bg-white-smoke d-flex align-items-center`}
                    id='chargingSpecs'
                >
                    <SpecsPanel
                        title={packageConfig.battery.specsPanel.label}
                        specs={packageConfig.battery.specsPanel.specs}
                    />
                </section>

                {/* Battery: Package Platforms */}
                <section
                    className={`fm-package-section fm-package-section--platform${packageConfig.battery.platformsPanel.image ? ' fm-full-height--sticky-footer' : ''} d-flex align-items-center`}
                >
                    <PackagePlatforms
                        {...packageConfig.battery.platformsPanel}
                    />
                </section>

            </div>
            {/* END Battery Sections container */}


            {/* Mosaic Section */}
            {/* <section
                className={`fm-package-section fm-scrollable-section`}
                data-scroll-label={'Gallery'}
                id={'gallery'}
            >
                <Mosaic
                    images={packageConfig.mosaic}
                />
                
            </section> */}
            {/* END Moasic Section */}


            <StickyButtons>

                <Button
                    label={'FAQs'}
                    theme={'secondary'}
                    url={'/faqs'}
                />

                <Button
                    label={'Configure'}
                    stateObj={{
                        modelId: packageConfig.id 
                    }}
                    theme={'accent'}
                    url={'/configure'}
                />

            </StickyButtons>
            
        </div>
  	)

}
