import { useEffect, useRef/* , useState */ } from 'react';
/* import React from 'react'; */

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/* import createScrollSnap from 'scroll-snap'; */

// Components
import PageTitle from '../components/PageTitle';
import HeroCarousel from '../components/global/HeroCarousel';
import Banner from '../components/global/Banner';
import ProductLineup from '../components/homepage-components/ProductLineup';
import ContentPanel from '../components/global/ContentPanel';
import MissionBanner from '../components/homepage-components/MissionBanner';
/* import ImageCarousel from '../components/global/ImageCarousel'; */
import Mosaic from '../components/global/Mosaic';

// Page Configuration Object
import homepageConfig from '../config/homepage/homepage-config';


export default function Home() {

    // Ref for parent container
    const containerRef = useRef(null);

    const page = homepageConfig;

  	return (

		<div
            className='fm-page'
            data-page='home'
            // The selector (class or id) of the text element relative to which the header background will fill in
            data-header-fill='.fm-hero-carousel__txt-wrap'
            /* onScroll={handleScroll} */
            ref={containerRef}
        >

            <PageTitle
                title='Home'
                desc={homepageConfig.metaDesc}
            />

            <h1 className='visually-hidden'>Revolutionizing Marine Electrification. 100% Electric Outboards.</h1>

            {/* Hero */}
            <section className='fm-homepage-hero fm-full-height p-0 ct-header ct-header--slider ct-slick-custom-dots'>
                <HeroCarousel
                    slides={page.hero}
                />
            </section>
            {/* END Hero */}


            {/* Product Lineup Carousels */}
            <section
                className='fm-lineup fm-full-height d-flex flex-column justify-content-center'
            >
                {/* <Banner
                    {...page.productLineup.banner}
                /> */}
                <div>
                    <ProductLineup 
                        {...page.productLineup}
                    />
                </div>
            </section>
            {/* END Product Lineup Carousels */}


            {/* Sea Trial & Dealer Text/Image Content Sections */}
            {page.contentPanels.map((panel, i) =>

                <section
                    className={'fm-experience fm-full-height'}
                    key={`${panel.title}-${i}`}
                >

                    <ContentPanel
                        {...panel}
                        textPosition={i % 2 === 0 ? 'left' : 'right'}
                    />

                </section>

            )}
            {/* END Sea Trial & Dealer Text/Image Content Sections */}


            {/* Mission Banner w/ Social Links */}
            <section 
                className='p-0'
            >
                <MissionBanner
                    {...page.missionBanner}
                />
            </section>
            {/* END Mission Banner w/ Social Links */}


            {/* Image Carousel */}
            {/* <section 
                className='fm-images'
            >
                <ImageCarousel
                    images={page.images}
                />
            </section> */}
            {/* END Image Carousel */}


            {/* Mosaic Section */}
            <section>
                <Mosaic
                    images={page.mosaic}
                />
                
            </section>
            {/* END Moasic Section */}

        </div>
  	)

}
