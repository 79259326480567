/* import { useRef, useEffect, useState } from 'react'; */

import { Link } from 'react-router-dom';

/*
    Button Component Props:

        customClass (string): additional class to add to the button (optional)
        icon (JSX): FontAwesome icon JSX to render after the Button text (optional)
        label (string): Text string to print on button (required)
        onClick (function): Click handler used for custom behavior (optional)
        stateObj (Object): An object to pass anything through to the destination via state (optional)
        target (string): Link target for opening in a new tab (optional)
        theme (string): 'std' (default), 'secondary', 'accent', 'secondary-accent' (optional)
        url (string): URL to which the button will direct on click (optional)
*/



export default function Button({ customClass, icon, label = 'Click Me', onClick, stateObj, target, theme, url }) {

    const buildClassString = () => {
        let classString = 'fm-btn';

        if (theme) {
            classString += ` fm-btn--${theme}`;
        }

        if (customClass) {
            classString += ` ${customClass}`;
        }

        return classString;
    };

    const handleClick = (e) => {
        e.preventDefault();
        onClick();
    };

	return (

        <Link
            className={buildClassString()}
            onClick={onClick && handleClick}
            state={stateObj}
            target={target}
            to={url || '#'}
        >

            <span>{label}</span>

            {icon && (icon)}

        </Link>

	)

}
