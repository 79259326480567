const hullConfig = {

    contentPanel: {

        // Image Source
        image: '/packages/inflatable/RIB-100Electric-v3.jpg',

        // Alternate text describing image contents (for use with a screenreader)
        altText: 'An electric Highield Rigid Inflatable Boat turns at speed kicking up spray.',

        // Heading
        title: '',

        // Must remain inside parenthesis and <></>; use <br /> for line breaks
        body: (<>
            <div className='ri-hull-content-panel__logos'>
                <img
                    alt='Scout logo'
                    className='ri-hull-content-panel__logo'
                    src={'https://d25bwcki5hef1e.cloudfront.net/brand/flux_logo_dark_gray_full.svg'}
                />
                <span className="ri-hull-content-panel__x">X</span>
                <img
                    alt='Scout logo'
                    className='ri-hull-content-panel__logo'
                    src={'https://d25bwcki5hef1e.cloudfront.net/packages/inflatable/highfield_logo.png'}
                />
            </div>
            The Rigid Inflatable boat package combines our outboard with Highfield's premium lightweight aluminum hull and durable Hypalon tubing.
            <br/><br/>
            We are working with Highfield Boats to curate the best RIBs across three hull sizes. These RIBs come with an integrated Flux Marine outboard, dashboard, battery, throttle system, charger, and portable charging cable.
            <br/><br/>
            A safe and reliable shuttle for families and friends. Take control of your day's excursions around your local waterways with a thrilling and pleasant ride.
        </>)

    },

    imagePanel: {

        altText: 'Electric Highfield Rigid Inflatable Boat from the front on calm lake waters at sunrise.',

        image: '/packages/inflatable/RIB-Hull.jpg'

    },

    /* images: [
        // First Slide
        {
            altText: 'A Flux Marine inflatable electric boat from behind on calm water.',
            image: '/packages/inflatable/image-carousel/slide_1.jpg'
        },

        // Second Slide
        {
            altText: 'A close-up view of a FM40 Flux Marine 40HP electric outboard.',
            image: '/packages/inflatable/image-carousel/slide_2.jpg'
        },

        // Third Slide
        {
            altText: 'A close-up view from the side of a Highfield rigid inflatable boat with a Flux Marine electric outboard.',
            image: '/packages/inflatable/image-carousel/slide_3.jpg'
        }
    ], */

    specs: [
        {
            label: 'LOA',
            unit: 'ft',
            value: '13-22'
        },
        {
            label: 'Beam',
            unit: 'ft',
            value: '6.5-8.5'
        },
        {
            label: 'Passengers',
            unit: '',
            value: '7+'
        },
        {
            label: 'Full Weight',
            unit: 'lbs',
            value: '950-3,400'
        }
    ]

};

export default hullConfig;