export default function Step(props) {

    const {
        additionalContent = false,
        active = false,
        choices,
        choiceDependency,
        choiceTemplate,
        clearOnChange,
        clickHandler,
        currSelection,
        fieldName,
        handleNextClick,
        nextStep,
        name,
        dependentSelection,
        stepNo
    } = props;

    let filteredChoices = choices;
    if (choiceDependency) {
        filteredChoices = choices.filter((choiceObj) => choiceObj.dependentIds.includes(dependentSelection));
    }


    return (
        
        <div className={`fm-studio__step ${active ? 'fm-studio__step--active' : ''}`}>

            <h2 className='fm-studio__section-title text-center'>{stepNo}. {name}</h2>

            <div className='fm-studio__options fm-studio__options--radio d-flex flex-column'>
                
                {filteredChoices.map((obj) =>
                    choiceTemplate ? 
                        choiceTemplate(obj, () => clickHandler(fieldName, clearOnChange, obj), currSelection) 
                        : ( 
                            <button
                                className={`fm-studio__btn basic-hover ${currSelection === obj.id ? 'fm-studio__btn--selected' : ''}`}
                                key={obj.id}
                                onClick={() => clickHandler(fieldName, clearOnChange, obj)}
                            >
                                {obj.name}
                                {obj.unit ? <>&nbsp;{obj.unit}</> : ''}
                                {obj.appendToChoice}
                            </button>
                        )
                )}

            </div>

            {additionalContent}

            <div className='fm-studio__bottom-nav text-center'>
                <button
                    className={`fm-btn ${!currSelection ? 'disabled' : ''}`}
                    onClick={handleNextClick}
                    tabIndex={!currSelection ? -1 : ''}
                >
                    <span>Next: {nextStep}</span>
                </button>
            </div>
        
        </div>

    );
}