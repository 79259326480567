const outboardConfig = {

    // Image Source
    image: '/packages/inflatable/RIB-Outboard.jpg',

    // Alternate text describing image contents (for use with a screenreader)
    altText: 'Electric Outboard on a Highfield Rigid Inflatable Boat viewed from behind in wide-angle at sunrise.',

    // Heading
    title: '100% Electric Outboard Motor',

    // Must remain inside parenthesis and <></>; use <br /> for line breaks
    body: (<>
        <p>
            Enjoy thrilling and reliable rides and never visit a fuel dock or change your oil again. We have paired the three hull sizes with our 40, 70 and 100 HP systems so you can choose the right RIB for your boating needs. Estimated cruising range on one charge can reach up to 30 miles.
        </p>
        <p>
            Charge when you want, where you want. Flux Marine outboards are compatible with 110V, 220V, 240V, and the growing network of DC Fast chargers.
        </p>
        <p>
            Enjoy a full day of boating from one night of charging.<br />Never visit a fuel dock again.
        </p>
    </>),

    // Button Text
    ctaText: 'More Outboard Info',

    // Button Relative URL
    ctaUrl: '/outboards'

};

export default outboardConfig;