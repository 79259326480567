import outboardCarouselConfig from "./outboard-carousel-config";
import packageCarouselConfig from "./package-carousel-config";


// Configuration Object
const productLineupConfig = {

    banner: {
        img: '/homepage/lineup_banner.png',
        text: 'Choose From Our Lineup'
    },

    title: 'Choose From Our Lineup',

    outboardsImg: '/homepage/home-outboards.jpg',

    outboardsTitle: 'Outboards',

    packagesImg: '/homepage/home-packages.jpg',

    packagesTitle: 'Boat Packages'

};
  
export default productLineupConfig;