import { useContext, useRef, useState } from 'react';

import Slider from 'react-slick';

import Button from './Button';

import { GlobalContext } from '../../contexts/GlobalContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function CustomBtn(props) {
    const { className, onClick, theme } = props;

    return (
        <div
            className={`${className} ${theme ? theme : ''}`}
            onClick={onClick}
        >
            { props.mode === 'next' ?
                (<FontAwesomeIcon icon='fa-solid fa-chevron-right' />)
                : (<FontAwesomeIcon icon='fa-solid fa-chevron-left' />)
            }
        </div>
    );
}


/*
    HeroCarousel Component Props:

        customClass (string): additional class to add to the button (optional)
        icon (JSX): FontAwesome icon JSX to render after the Button text (optional)
        label (string): Text string to print on button (required)
        onClick (function): Click handler used for custom behavior (optional)
        stateObj (Object): An object to pass anything through to the destination via state (optional)
        target (string): Link target for opening in a new tab (optional)
        theme (string): 'std' (default), 'secondary', 'accent' (optional)
        url (string): URL to which the button will direct on click (optional)
*/
export default function HeroCarousel({ slides }) {

    const { cdnUrl } = useContext(GlobalContext);

    const sliderRef = useRef(null);

    const [slideTheme, setSlideTheme] = useState(slides[0].theme || '');

    const themeArrows = (slide) => {
        setSlideTheme(slide.theme ? slide.theme : '');
    };

    const settings = {
        afterChange: (current) => themeArrows(slides[current]),
        autoplay: true,
        autoplaySpeed: 5500,
        dots: true,
        draggable: false,
        infinite: true,
        nextArrow: <CustomBtn mode='next' theme={slideTheme} />,
        prevArrow: <CustomBtn mode='prev' theme={slideTheme} />,
        speed: 600,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0
    };

    const buildPositionalClasses = (posX = 'left', posY = 'top') => {
        const baseStr = 'fm-hero-carousel__container--';
        let classStr = '';

        classStr += baseStr.concat(posX);
        classStr += ' ';
        classStr += baseStr.concat(posY);

        return classStr;
    };

	return (

        <>

            <Slider 
                className={`fm-hero-carousel fm-carousel`}
                ref={sliderRef}
                {...settings}
            >
                {slides.map((slide, i) =>

                    <div
                        className='fm-hero-carousel__slide h-100'
                        data-slide={i+1}
                        key={i}
                    >
                        <div
                            className={`fm-hero-carousel__container ${buildPositionalClasses(slide.textPositionX, slide.textPositionY)} h-100`}
                            style={{
                                backgroundImage: `url('${cdnUrl}${slide.image}')`
                            }}
                        >

                            <span className='visually-hidden'>Slide {i+1} of {slides.length}. {slide.altText}</span>


                            <div className={`fm-hero-carousel__txt-wrap ${slide.theme ? `fm-hero-carousel__txt-wrap--${slide.theme}` : ''}`}>

                                <h2 className='fm-hero-carousel__title text-center text-md-start'>
                                    {slide.text}
                                </h2>
                            
                                <Button
                                    label={slide.ctaText}
                                    stateObj={slide.stateObj}
                                    theme={'secondary'}
                                    url={slide.ctaUrl}
                                />

                            </div>

                        </div>

                    </div>

                )}

            </Slider>

        </>

	);

}
