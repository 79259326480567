import { useContext, useEffect, useRef, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { GlobalContext } from '../contexts/GlobalContext';

import debounce  from 'lodash/debounce';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import PageTitle from '../components/PageTitle';
import SectionHeader from '../components/global/SectionHeader';
import OutboardHero from '../components/outboard-components/OutboardHero';
import ModelPanel from '../components/outboard-components/ModelPanel';
import ScrollPanel from '../components/global/ScrollPanel';
import OutboardDesign from '../components/outboard-components/OutboardDesign';
import ContentPanel from '../components/global/ContentPanel';
import SpecsTable from '../components/outboard-components/SpecsTable';
import BgImgContainer from '../components/global/BgImgContainer';
import StickyButtons from '../components/global/StickyButtons';
import Button from '../components/global/Button';

// Utilities
import useOnScreen from '../lib/element-on-screen';
import scrollUtil from '../lib/scroll-utility';

// Configuration Files
import outboardsConfig from '../config/outboards/outboards-config';

// SVGs
import { ReactComponent as DashboardSVG} from '../assets/svgs/software_garmin_v4.svg';


export default function Outboards() {

    const { cdnUrl } = useContext(GlobalContext);

    const page = outboardsConfig;

    // Retrieve state passed from Link element (for auto-scrolling)
    const location = useLocation();

    //const softwareSectionRef = useRef();
    //const softwareIsVisible = useOnScreen(softwareSectionRef/* , '-65px 0px -65% 0px' */);

    /* const scrollSnapRef = useRef(null);
    useScrollSnap({ ref: scrollSnapRef, duration: 100 }); */

    const [futureModelsExpanded, setFutureModelsExpanded] = useState(false);

    const [ dashboardRef, dashboardIsVisible ] = useOnScreen({
        rootMargin: '-65px 0px -68px 0px',
        threshold: 0.95
    }, true);

    /* const designSectionRef = useRef();
    const designIsVisible = useOnScreen(designSectionRef, '-65px 0px 68px 0px');
    console.log(designIsVisible); */

    const [ techRef1, techRef1IsVisible ] = useOnScreen({
        rootMargin: '-65px 0px -68px 0px',
        threshold: 0.51
    }, false);

    const [ techRef2, techRef2IsVisible ] = useOnScreen({
        rootMargin: '-65px 0px -68px 0px'
    }, false);

    const addCollapseListeners = () => {
        const futureModelsCollapsible = document.getElementById('future-models-collapse');

        futureModelsCollapsible.addEventListener('shown.bs.collapse', function () {
            setFutureModelsExpanded(true);
        });
        futureModelsCollapsible.addEventListener('hide.bs.collapse', function () {
            setFutureModelsExpanded(false);
        });
    }

    const checkScrollSnap = () => {
        const windowHeight = window.innerHeight,
            headerHeight = document.querySelector('.fm-header').clientHeight,
            frameHeight = windowHeight - headerHeight,
            upperSectionPos = techRef1.current.getBoundingClientRect().bottom,
            lowerSectionPos = techRef2.current.getBoundingClientRect().top;

        if ( (lowerSectionPos < frameHeight) && (upperSectionPos > headerHeight) ) {
            if (upperSectionPos >= (frameHeight * 0.51)) {
                scrollUtil.scrollToSection(techRef1);
            } else {
                scrollUtil.scrollToSection(techRef2);
            }
        }

        return false;
    };

    const handleScroll = () => {
        debounce(() => {
            checkScrollSnap();
        }, 400);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        addCollapseListeners();

        return () => window.removeEventListener('scroll', handleScroll);

    }, []);

    useEffect(() => {
        const locState = location.state;
        if (locState && locState.scrollTo) {
            setTimeout(() => {
                document.querySelector(`.fm-scroll-list__link[href="#${locState.scrollTo}"]`).click();
            }, 350);
        }
    }, [location]);

  	return (
		<div
            className='fm-page fm-page--sticky-footer'
            data-page='outboards'
            // The selector (class or id) of the text element relative to which the header background will fill in
            data-header-fill='.fm-outboard-hero h1'
            // The selector (class or id) of the section/div element relative to which the sticky bottom bar will animate in
            data-sticky-footer='.fm-outboard-hero'
        >

            <PageTitle
                title='Outboards'
                desc={outboardsConfig.metaDesc}
            />

            {/* Outboards Hero section */}
            <section
                className='fm-scrollable-section fm-full-height p-0'
                data-scroll-label='Overview'
                id='overview'
            >
                <OutboardHero config={outboardsConfig.hero} />
            </section>
            {/* END Outboards Hero section */}


            {/* Technology Section */}
            <div
                className='fm-scrollable-section fm-full-height--sticky-footer-lg'
                data-scroll-label={'Technology'}
                id='technology'
            >

                {/* Technology: Available Today */}
                <section
                    className='fm-full-height--sticky-footer d-flex align-items-center'
                    id='available-today'
                >
                    <div className='fm-max-out w-100'>
                        
                        <SectionHeader
                            title={'Reserve Your System Today With Select Boat Packages'}
                        />

                        <div className='fm-available-outboards d-flex flex-column flex-lg-row align-items-center align-items-lg-start'>

                            {page.tech.availableToday.map((model, i) =>
                                <ModelPanel
                                    key={model.label.text}
                                    {...model}
                                />
                            )}

                        </div>

                    </div>
                </section>

                {/* Technology: Better Boating Experience */}
                <section
                    className={`bg-white-smoke`}
                    id='better-boating'
                >

                    <div className='fm-max-out'>

                        <SectionHeader
                            title={page.tech.betterBoating.title}
                        />

                        <p>
                            {page.tech.betterBoating.body}
                        </p>

                    </div>

                </section>

                {/* Technology: Scroll Panel */}
                <section
                    className={`fm-full-height--sticky-footer-lg p-0`}
                    id='tech-scroll-panel'
                >
                    <ScrollPanel
                        {...page.tech.scrollPanel}
                    />
                </section>

                {/* <div ref={scrollSnapRef}> */}

                    {/* Technology: Sounds of the Water */}
                    <section
                        className='fm-full-height--sticky-footer-lg p-0'
                        id='tech-content-panel'
                        ref={techRef1}
                    >

                        <ContentPanel
                            {...page.tech.contentPanel}
                            textPosition={'left'}
                        />

                    </section>

                    {/* Technology: Simplfied Design */}
                    <section
                        className={`fm-full-height--sticky-footer p-0`}
                        id='tech-design'
                        ref={techRef2}
                        /* ref={designSectionRef} */
                    >
                        <OutboardDesign
                            {...page.tech.design}
                        />
                    </section>

                {/* </div> */}
            
            </div>
            {/* END Technology Section */}


            {/* Charging Section */}
            <div
                className={'fm-scrollable-section'}
                data-scroll-label={page.charging.label}
                id={page.charging.id}
            >

                {/* Charging: Content Panel */}
                <section
                    className='fm-full-height--sticky-footer-lg p-0'
                >
                    <ContentPanel
                        {...page.charging.contentPanel}
                        textPosition={'left'}
                    />
                </section>

            </div>
            {/* END Charging Section */}


            {/* Software Section */}
            <div
                className={'fm-scrollable-section'}
                data-scroll-label={page.software.label}
                id={page.software.id}
            >

                {/* Software: Full Size Image */}
                <section
                    className={`fm-full-height--sticky-footer-lg p-0`}
                    id='softwareImage'
                >
                   <BgImgContainer
                        img={page.software.imagePanel}
                   />
                </section>

                {/* Software: Command Your Experience */}
                <section
                    className={`fm-full-height--sticky-footer-lg ${dashboardIsVisible ? 'in-view' : ''}`}
                    id='software-dash'
                    /* ref={softwareSectionRef} */
                >

                    <SectionHeader
                        title={'Command Your Experience'}
                        subtitle={'Monitor your speed, state of charge, and range with the tap of a finger using the Flux dashboard on Garmin chartplotters.'}
                    />

                    <div
                        className='software__img-container'
                        ref={dashboardRef}
                    >
                        {/* <img className='img-fluid' src={`${cdnUrl}/outboards/software/software_garmin_v2.png`} /> */}
                        <img className='img-fluid' src={`${cdnUrl}/outboards/software/software_garmin_v4.svg`} />
                        {/* <DashboardSVG /> */}
                    
                        {/* <div className='software__dash-tooltip' id='dash-tooltip-1'>
                            Adjusted range based on usage
                        </div>

                        <div className='software__dash-tooltip' id='dash-tooltip-2'>
                            Tap to switch between HP, power percentage, and KW usage
                        </div>

                        <div className='software__dash-tooltip' id='dash-tooltip-3'>
                            Monitor the charge of both high and low voltage batteries
                        </div> */}
                    </div>

                </section>

                {/* Software: Mobile Integration */}
                <section
                    className='fm-full-height--sticky-footer-lg p-0'
                    id='software-mobile'
                >

                    <ContentPanel
                        {...page.software.contentPanel}
                        textPosition={'right'}
                    />

                </section>

            </div>
            {/* END Software Section */}


            {/* Future Offerings Section */}
            <div
                className='fm-scrollable-section'
                data-scroll-label={page.future.label}
                id={page.future.id}
            >
                <div
                    className={`fm-collapsible${futureModelsExpanded ? ' fm-collapsible--expanded' : ''} fm-full-height--sticky-footer`}
                    id='future-models'
                >
                    <section className='bg-white-smoke'>
                        <div className='fm-max-out w-100'>
                            <button
                                aria-controls={'future-models-collapse'}
                                aria-expanded={'false'}
                                className='fm-collapsible__btn d-flex flex-row justify-content-between align-items-center text-start'
                                data-bs-target={`#future-models-collapse`}
                                data-bs-toggle='collapse'
                            >

                                <SectionHeader
                                    title={page.future.sectionTitle}
                                />

                                <div>
                                    <FontAwesomeIcon className='fm-collapsible__icon' icon={`fa-solid ${futureModelsExpanded ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
                                    <span className='visually-hidden'>{futureModelsExpanded ? 'Collapse' : 'Expand'} Future Models Section</span>
                                </div>

                            </button>
                        </div>
                    </section>

                    <div
                        className='collapse'
                        id='future-models-collapse'
                    >

                        {/* Future Offerings: Reserve Models */}
                        <section
                            className='fm-full-height--sticky-footer bg-white-smoke'
                            id='future-models-reserve'
                        >
                            <div className='fm-max-out w-100'>
                                <h3 className='text-center'>
                                    {page.future.reserveLabel}
                                </h3>

                                <div className='fm-available-outboards d-flex flex-column flex-lg-row align-items-center align-items-lg-start'>

                                    {page.future.futureModels.map((model, i) =>
                                        <ModelPanel
                                            key={model.label.text}
                                            {...model}
                                        />
                                    )}

                                </div>
                            </div>
                        </section>

                        {/* Future Offerings: Repower Section (Large SVG Image) */}
                        <section
                            className='fm-full-height--sticky-footer-lg d-flex justify-content-center align-items-center'
                            id='future-repower'
                        >
                            <div className='fm-max-out w-100'>
                                <h3 className='text-center'>{page.future.repower.title}</h3>

                                <img
                                    alt={page.future.repower.altText}
                                    className='img-fluid d-lg-none'
                                    src={`${cdnUrl}${page.future.repower.mobileImg ? page.future.repower.mobileImg : page.future.repower.desktopImg}`}
                                    width={852}
                                    height={575}
                                />

                                <img
                                    alt={page.future.repower.altText}
                                    className='img-fluid d-none d-lg-block mx-auto'
                                    src={`${cdnUrl}${page.future.repower.desktopImg}`}
                                    width={1043}
                                    height={482}
                                />
                            </div>
                        </section>

                    </div>
                </div>

            </div>
            {/* END Future Offerings Section */}


            {/* Specs Table section */}
            <section
                className='fm-scrollable-section fm-full-height--sticky-footer'
                data-scroll-label='Specs'
                id={'specs'}
            >

                <div className='fm-max-out'>

                    <SpecsTable data={page.tableSpecs} />

                </div>

            </section>
            {/* END Specs Table Section */}


            {/* Sticky Bottom Bar */}
            <StickyButtons>

                <Button
                    label={'FAQs'}
                    theme={'secondary'}
                    url={'/faqs'}
                />

                <Button
                    label={'Configure'}
                    stateObj={{
                        modelId: 4
                    }}
                    theme={'accent'}
                    url={'/configure'}
                />

            </StickyButtons>
            {/* END Sticky Bottom Bar */}

        </div>
  	)

}