const hbspotFormUtil = {

    region: 'na1',

    portalId: '24343252',

    injectForm: (formId, injectionTarget) => {
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    region: hbspotFormUtil.region,
                    portalId: hbspotFormUtil.portalId,
                    formId: formId,
                    target: injectionTarget
                });

            }
        });
    },

    handleSubmission: (formId, submitHandler, callback) => {
        window.addEventListener('message', event => {
            if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted' && event.data.id === formId) {
                submitHandler();
                console.log("This form has been submitted!");
                setTimeout(callback, 750);
            }
        });
    }

};

export default hbspotFormUtil;