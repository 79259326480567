const hullConfig = {

    contentPanel: {

        // Image Source
        image: '/packages/center-console/center_console_electric.jpg',

        // Alternate text describing image contents (for use with a screenreader)
        altText: 'Electric Center Console boat moves through the water at speed from behind.',

        // Heading
        title: '',

        // Must remain inside parenthesis and <></>; use <br /> for line breaks
        body: (<>
            <div className='dc-hull-content-panel__logos'>
                <img
                    alt='Scout logo'
                    className='dc-hull-content-panel__logo'
                    src={'https://d25bwcki5hef1e.cloudfront.net/brand/flux_logo_dark_gray_full.svg'} /* flux_logo_dark_full.png */
                />
                <span className="dc-hull-content-panel__x">X</span>
                <img
                    alt='Scout logo'
                    className='dc-hull-content-panel__logo'
                    src={'https://d25bwcki5hef1e.cloudfront.net/packages/dual-console/scout_logo.jpg'}
                />
            </div>
            Our Center Console has been purpose-built around the Flux propulsion system. Enjoy industry leading performance and harmonious technology integration.
            <br /><br />
            Whether you're embarking on a day of watersports or enjoying a sunset cruise, this Center Console is built for versatility.
            <br /><br />
            We have paired the Scout 215 XSF hull with our 100 HP electric motor producing a top speed of 30mph and 30+ miles of boating from a single charge.
        </>),

        scoutLogo: '/packages/dual-console/scout_logo.jpg'
    },

    imagePanel: {

        altText: 'An electric Center Console boat moves in the water.',

        image: '/packages/center-console/center_console_hull.jpg'

    },

    specs: [
        {
            label: 'LOA',
            unit: 'ft',
            value: '21.5'
        },
        {
            label: 'Beam',
            unit: 'ft',
            value: '8.5'
        },
        {
            label: 'Passengers',
            unit: '',
            value: '9'
        },
        {
            label: 'Full Weight',
            unit: 'lbs',
            value: '4,000'
        }
    ]

};

export default hullConfig;