import { useEffect } from 'react';

export default function PageTitle({ canonical, desc, title }) {

    useEffect(() => {
        const titleStr = 'Flux Marine' + `${title ? ` | ${title}` : ''}`;
        document.title = titleStr;
        document.querySelector('meta[property="twitter:title"]').setAttribute('content', titleStr);
        document.querySelector('meta[property="og:title"]').setAttribute('content', titleStr);

        if (desc) {
            document.querySelector('meta[name="description"]').setAttribute('content', desc);
            document.querySelector('meta[property="twitter:description"]').setAttribute('content', desc);
        }

        if (canonical) {
            // https://www.fluxmarine.com/
            document.querySelector('link[rel="canonical"]').setAttribute('href', `https://www.fluxmarine.com/${canonical}`);
        }

    });

    return null;
}