
import { useRef, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import Modal from '../global/Modal';
import Loader from '../../components/global/Loader';
import FormFeedback from '../../components/form-components/FormFeedback';

import { formsConfig } from '../../config/forms/forms-config';

// Hubspot Form Utility
import hbspotFormUtil from '../../lib/hubspot-form-utility';


export default function SubscriptionModal({ closeFunc, show = false }) {

    const [showLoader, setShowLoader] = useState(false);
    const [completed, setCompleted] = useState(false);

	const closeAndClear = () => {
		closeFunc();
        setTimeout(() => {
            hbspotFormUtil.injectForm(formsConfig.subscribe, '#fm-subscr-form-wrapper');
            setShowLoader(false);
            setCompleted(false);
        }, 1000);
	};

    /* const validateSubscriptionFields = () => {
        return subscriptionFields.first
                && subscriptionFields.last      
                && (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i).test(subscriptionFields.email);
    } */

    // Inject Hubspot Form
    useEffect(() => {
        hbspotFormUtil.injectForm(formsConfig.subscribe, '#fm-subscr-form-wrapper');
        hbspotFormUtil.handleSubmission(
            formsConfig.subscribe,
            () => {
                setShowLoader(true);
            },
            () => { setCompleted(true); setShowLoader(false); }
        );
    }, []);


	return (

        <Modal
            closeModal={closeAndClear}
            /* footerContent={
                <div className='d-flex justify-content-center'>
                    <button className={`fm-btn ${!validated && 'disabled'}`}>
                        <span>Subscribe</span>
                    </button>
                </div>
            } */
            id='footerSubscriptionModal'
            title={'Subscribe to our Newsletter'}
            show={show}
        >
            <div
                className={`${showLoader || completed ? 'd-none' : '' }`}
                id='fm-subscr-form-wrapper'
            >
                {/* Hubspot form goes here */}
            </div>

            {showLoader && !completed && (
                <Loader size={'lg'} />
            )}

            {completed && (
                <FormFeedback
                    ctaClickHandler={closeAndClear}
                    ctaLabel='Close'
                    homepageBtn={false}
                    message='Your response has been submitted and received.'
                />
            )}
            
        </Modal>

	)
}
