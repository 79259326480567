import { useContext } from 'react';

import { GlobalContext } from '../../contexts/GlobalContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import videojs from 'video.js';
import 'video.js/dist/video-js.css';

// SVG Assets

export default function BgVideoContainer({ maxHeightOverride, onReady, options }) {

    const { cdnUrl } = useContext(GlobalContext);

    return (
        <div className='fm-bg-vid-container' style={{width: '100%', height: '100%', maxHeight: maxHeightOverride || 'calc(100vh - 4rem - 4.25rem - 2.5rem - 2.8125rem)'}}>

            <video
                className='fm-bg-vid-container__video'
                src={`${cdnUrl}${options.sources[0].src}`}
                autoPlay
                loop={typeof(options.loop) === 'boolean' ? options.loop : true}
                muted
                playsInline
                style={{
                    width: '100%',
                    height: '100%',
                    maxHeight: maxHeightOverride || 'calc(100vh - 4rem - 4.25rem - 2.5rem - 2.8125rem)',
                    /* objectFit: 'cover' */
                }}
            />

        </div>
    );
}



/* export default function BgVideoContainer({ onReady, options }) {

    const videoRef = useRef(null);
    const playerRef = useRef(null);

    useEffect(() => {

        // Make sure Video.js player is only initialized once
        if (!playerRef.current) {
            const videoElement = videoRef.current;
    
            if (!videoElement) return;

            const player = playerRef.current = videojs(videoElement, options, () => {
                // videojs.log('player is ready');
                onReady && onReady(player);
            });
    
        // You could update an existing player in the `else` block here
        // on prop change, for example:
        } else {
            const player = playerRef.current;

            // player.autoplay(options.autoplay);
            player.src(options.sources);
        }

    }, [options, videoRef]);


    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player) {
                player.dispose();
                playerRef.current = null;
            }
        };

    }, [playerRef]);

    return (
        <div className='fm-video-inline' data-vjs-player>

            <video ref={videoRef} className='video-js vjs-big-play-centered' />

        </div>
    );
} */

