// Configuration Object
const contentPanelsConfig = [

    {
        // Image Source
        image: '/homepage/schedule_sea_trial.jpg',

        // Alternate text describing image contents (for use with a screenreader)
        altText: 'A close up of an electric outboard motor at speed with the wake behind it.',

        // Heading
        title: 'Book a Sea Trial',

        // Subheading
        // subhead: 'Experience electric boating.',

        // Must remain inside parenthesis and <></>; use <br /> for line breaks
        body: (<>
            The best way to understand the true capabilities of a Flux Marine outboard is to take it for a ride on the water. Our demo boats showcase the latest advancements in design, performance, and technology of electric propulsion. You'll have the opportunity to explore the features, test the handling, and feel the instant torque. 
        </>),

        // Button Text (optional)
        ctaText: 'Schedule a Sea Trial',

        // Button URL (optional)
        /* ctaUrl: '/sea-trial' */
        ctaUrl: '/about',

        stateObj: { 
            scrollTo: 1,
            inquiry: 'Request a Sea Trial'
        }
    },
    {
        // Image Source
        image: '/homepage/become_a_dealer_latest.jpg',

        // Alternate text describing image contents (for use with a screenreader)
        altText: 'A close up of an electric outboard motor on calm waters at sunrise.',

        // Heading
        title: 'Become a Dealer',

        // Subheading
        // subhead: 'Become a dealer',

        // Must remain inside parenthesis and <></>; use <br /> for line breaks
        body: (<>
            We are partnering with dealers, hull manufacturers, and service providers to aid in the transition to make every boat electric.
            <br /><br />
            Join us to enhance your business and boating community. Reach out to learn more about how our technology can decrease maintenance costs and improve the boating experience for your customers.
        </>),

        // Button Text (optional)
        ctaText: 'Contact Us',

        // Button URL (optional)
        ctaUrl: '/about',

        stateObj: { scrollTo: 1 }
    }
];


export default contentPanelsConfig;